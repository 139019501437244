// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import { Grid, IconButton } from "@mui/material";
import MDAvatar from "components/MDAvatar";
import { useNavigate } from "react-router-dom";
import { Delete, Edit, RemoveRedEye } from "@mui/icons-material";
import { dateTimeFormat } from "utils/clientFunctions";
import { dateFormat } from "utils/clientFunctions";

function MobileList({
  invoiceItem,
  handleOpenDialog,
  index,
  handlePrintDownloadInvoice,
  showEditButton,
  showDeleteButton,
  handleOpenDeleteDialog
}) {
  let {
    invoice_id,
    invoice_date,
    invoice_number,
    bill_name,
    total_invoice_amount,
    due_date,
    status_info,
    br_currency_info,
  } = invoiceItem;
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <Grid container p={2} spacing={2}>
      <Grid item xs={12} md={12} lg={12}>
        <MDBox
          borderRadius="lg"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={1}
          sx={{
            border: ({ borders: { borderWidth, borderColor } }) =>
              `${borderWidth[1]} solid ${borderColor}`,
          }}
        >
          <MDBox width="100%" display="flex" flexDirection="column">
            <MDBox
              mb={1}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <MDTypography variant="caption" fontWeight="bold">
                {index}
              </MDTypography>
              <MDTypography variant="caption" fontWeight="bold">
                {dateFormat(invoice_date)}
              </MDTypography>
            </MDBox>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems={{ xs: "center", sm: "center" }}
              flexDirection={{ xs: "row", sm: "row" }}
              // mb={2}
            >
              {/* "Sl number and Invoice date on top row Invoice number | Vendor
              name | Amount | Status | VED" */}
              <MDBox>
                <MDBox mb={1} lineHeight={0}>
                  <MDTypography variant="caption" fontWeight="bold">
                    Invoice Number : {invoice_number}
                  </MDTypography>
                </MDBox>
                <MDBox mb={1} lineHeight={0}>
                  <MDTypography variant="caption" fontWeight="bold">
                    Vendor Name : {bill_name}
                  </MDTypography>
                </MDBox>
                <MDBox mb={1} lineHeight={0}>
                  <MDTypography variant="caption" fontWeight="bold">
                    Amount :
                    {br_currency_info?.currency_code +
                      " " +
                      total_invoice_amount * 1}
                  </MDTypography>
                </MDBox>
                <MDBox mb={1} lineHeight={0}>
                  <MDTypography variant="caption" fontWeight="bold">
                    Status : {status_info?.setting_value}
                  </MDTypography>
                </MDBox>
              </MDBox>
            </MDBox>
            <MDBox
              mt={2}
              display="flex"
              justifyContent="space-evenly"
              alignItems="center"
            >
              <MDButton
                variant="outlined"
                color="warning"
                size="small"
                onClick={() =>
                  handlePrintDownloadInvoice(invoiceItem, true, false)
                }
              >
                Print
              </MDButton>
              <MDButton
                variant="outlined"
                color="secondary"
                size="small"
                onClick={() =>
                  handlePrintDownloadInvoice(invoiceItem, false, true)
                }
              >
                Download
              </MDButton>
              <MDButton
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => navigate(`/invoice/view/${invoice_id}`)}
              >
                View
              </MDButton>
              {showEditButton && (
                <MDButton
                  variant="outlined"
                  size="small"
                  color="info"
                  onClick={() => navigate(`/invoice/${invoice_id}`)}
                >
                  Edit
                </MDButton>
              )}
              {showDeleteButton && (
                <MDButton variant="outlined" size="small" color="error"
                onClick={()=>{
                  handleOpenDeleteDialog(invoice_id)
                }}
                >
                  Delete
                </MDButton>
              )}
            </MDBox>
          </MDBox>
        </MDBox>
      </Grid>
    </Grid>
  );
}

// Setting default values for the props of Bill
MobileList.defaultProps = {
  noGutter: false,
};

export default MobileList;
