// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { fetchData, deleteData } from "utils/clientFunctions";
import { useEffect, useRef, useState } from "react";
import MDButton from "components/MDButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import MDInput from "components/MDInput";
import MDAvatar from "components/MDAvatar";
import { Delete, Edit, RemoveRedEye } from "@mui/icons-material";
import LeadRes from "../components/leadRes";
import { useAuth } from "utils/auth";
import pxToRem from "assets/theme/functions/pxToRem";
import { dateFormat } from "utils/clientFunctions";
import { CardTopBoxAdd } from "layouts/commonComponents/cardTopBoxAdd";
import { useSelector, useDispatch } from "react-redux";
import { getPermissions, selectPermissions } from "reducers/permissionSlice";
import { liveSearchData } from "layouts/commonComponents/liveSearchData";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { CSVDownload, CSVLink } from "react-csv";
import DownloadIcon from "@mui/icons-material/Download";
import { firstLetterCapital } from "utils/clientFunctions";
import { useToasts } from "react-toast-notifications";
import { dateTimeStamp } from "utils/clientFunctions";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function LeadsList() {
  const csvLink = useRef();
  const [pageIndex, setPageIndex] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [pageResize, setPageResize] = useState(
    window.innerWidth < 992 ? true : false
  );
  const navigate = useNavigate();
  const [List, setList] = useState([]);
  const [raData, setRaData] = useState({});
  const { logout } = useAuth();
  const { addToast } = useToasts();
  const [countryList, setCountryList] = useState([]);
  const [usersList, setUserList] = useState([]);
  const [statusList, setstatusList] = useState([]);
  const [positionList, setPositionList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [applicantList, setApplicantList] = useState([]);
  const [raList, setRaList] = useState([]);

  //Start : For permission
  const dispatch = useDispatch();
  var permissions = useSelector(selectPermissions);
  if (
    !(
      permissions?.user_id === 1 || permissions?.permission?.includes("ledView")
    )
  ) {
    navigate("/dashboard");
  }
  let showAddButton =
    permissions?.user_id === 1 || permissions?.permission?.includes("ledCreate")
      ? true
      : false;
  let showEditButton =
    permissions?.user_id === 1 || permissions?.permission?.includes("ledEdit")
      ? true
      : false;
  let showDeleteButton =
    permissions?.user_id === 1 || permissions?.permission?.includes("ledDelete")
      ? true
      : false;
  useEffect(() => {
    dispatch(getPermissions());
  }, []);
  //End : For permission

  const initialLoadData = async () => {
    let queryUrl = getQueryParams();
    const url = `leads/list${queryUrl}`;
    const data = await fetchData(url);
    if (
      data?.name === "JsonWebTokenError" ||
      data?.name === "TokenExpiredError" ||
      data?.message === "You are not authorized"
    ) {
      logout();
    }
    if (data && data.data) {
      setPageCount(Math.ceil(data.count / 10));
      setList(data.data || []);
    }
  };
  const leadOptions = [
    { label: "Recruiting agency", value: "ra" },
    { label: "Company", value: "company" },
    { label: "Applicant", value: "applicant" },
  ];

  const priorityOptions = [
    { label: "Low", value: "Low" },
    { label: "Medium", value: "Medium" },
    { label: "High", value: "High" },
  ];
  useEffect(() => {
    const countryUrl = `country/list`;
    getFetchData(countryUrl).then((res) => {
      setCountryList(res.data);
    });

    const statusUrl = `setting/type/8`;
    getFetchData(statusUrl).then((res) => {
      setstatusList(res.data);
    });

    //Get company list
    const companyApiUrl = `company/list?page=1`;
    getFetchData(companyApiUrl).then((res) => {
      setCompanyList(res.data);
    });

    const applicantUrl = `applicant/list?page=1`;
    getFetchData(applicantUrl).then((res) => {
      setApplicantList(res.data);
    });

    const raUrl = `recruiting_agency/list?page=1`;
    getFetchData(raUrl).then((res) => {
      setRaList(res.data);
    });

    //Get position list
    const positionUrl = `setting/type/4`;
    getFetchData(positionUrl).then((res) => {
      setPositionList(res.data);
    });
  }, []);

  const searchSpot = (value, key) => {
    liveSearchData({
      countryId: null,
      value,
      key,
      setRaList,
      setCompanyList,
      setApplicantList,
      setUserList,
    });
  };
  const getFetchData = async (settingUrl) => {
    return await fetchData(settingUrl);
  };
  //Search & Filter initial data
  const [searchFormData, setSearchFormData] = useState({
    lead_type: "",
    lead_type_name: "",
    country_id: "",
    priority: "",
    status_id: "",
  });

  useEffect(() => {
    initialLoadData();
  }, [
    pageIndex,
    searchFormData.lead_type,
    searchFormData.lead_type_name,
    searchFormData.country_id,
    searchFormData.priority,
    searchFormData.status_id,
  ]);

  const doPaginationChange = (event, value) => {
    setPageIndex(value);
  };

  //Dialog popup
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogForDelete, setDialogForDelete] = useState(false) // Dialog for deleting the task
  const [selectedLeadId, setSelectedLeadId] = useState(null);   // state to store task_id
  const handleOpenDialog = (raInfo) => {
    setRaData(raInfo);
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setRaData({});
    setOpenDialog(false);
  };

  const handleOpenDeleteDialog = (lead_id) => {
    setDialogForDelete(true);
    console.log("Lead Id to delete : for debugging", lead_id);
    setSelectedLeadId(lead_id);
  }
  const handleCloseDeleteDialog = () =>{
    setDialogForDelete(false);
    setSelectedLeadId(null); // clear the selected lead Id
  }

  window.addEventListener("resize", () => {
    if (window.innerWidth < 992) {
      setPageResize(true);
    } else {
      setPageResize(false);
    }
  });

  const DeleteLead = ({openDialogForDelete, handleCloseDeleteDialog}) =>{

    const handleConfirmDelete = async () => {
      if(!selectedLeadId) {
        console.error("No lead selected for deletion");
        return;
      }

      //Close the dialog immediately
      setDialogForDelete(false);

      //Add logic to handle lead deletion
      const url = `leads/${selectedLeadId}`;
      try {
        const response = await deleteData(url);

        if(response?.status) {
          // Success handling
          addToast("Lead deleted successfully", {
            appearance: "success",
            autoDismiss: true,
          });

          //Refresh the lead list
          setList((prevList) => prevList.filter((lead) => lead.lead_id !== selectedLeadId));

          //Reset the selected lead id
          setSelectedLeadId(null);
        } else {
          // Handle errors
          if (response?.message) {
            addToast(response.message, { appearance: "error", autoDismiss: true });
          }
          if (response?.errors) {
            response.errors.forEach((error) => {
              let errorMessage = error.message
                .replace(/['"]+/g, "")
                .replace(/_/g, " ");
              errorMessage =
                errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
              addToast(errorMessage, { appearance: "error", autoDismiss: true });
            });
          }
        }
      }catch (error) {
        console.error("Error deleting Lead:", error);
        addToast("An error occurred while deleting the Lead.", {
          appearance: "error",
          autoDismiss: true,
        });
      }
    }

    return(<>
    <BootstrapDialog
      onClose={handleCloseDeleteDialog}
      aria-labelledby="customized-dialog-title"
      open={openDialogForDelete}
      fullScreen={false}
      >
      <DialogTitle sx={{ m: 0 }} id="customized-dialog-title">
          {"Delete Lead"}
        </DialogTitle>
        <IconButton
        aria-label="close"
        onClick={handleCloseDeleteDialog}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <MDTypography variant="body1" gutterBottom>
          Are you sure you want to delete this Lead?
        </MDTypography>
      </DialogContent>
      <DialogActions>
        <MDButton
          onClick={handleConfirmDelete}
          variant="gradient"
          color="error"
          size="small"
        >
          Delete
        </MDButton>
        <MDButton
          onClick={handleCloseDeleteDialog}
          variant="gradient"
          color="info"
          size="small"
        >
          Cancel
        </MDButton>
      </DialogActions>   
      </BootstrapDialog>
    </>)

  }

  const ViewMoreRa = ({ openDialog, raData, handleCloseDialog }) => {
    const {
      lead_id,
      priority,
      start_date,
      end_date,
      contact_number,
      email_id,
      lead_type,
      lead_type_name,
      website,
      industry_value,

      position_value,
      remarks,
      status_value,
      size_of_company_value,
      country_name,
      country_code,
      assign_user_first_name,
      assign_user_last_name,
      contact_code_name,
      contact_country_code,
      contact_code_dial_code,
    } = raData;

    return (
      <BootstrapDialog
        onClose={handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
        fullScreen={window.innerWidth < 768 ? true : false}
      >
        <DialogTitle sx={{ m: 0 }} id="customized-dialog-title">
          {"Lead Details"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid container px={2} spacing={1}>
            <ViewTypo
              title={"Lead Type"}
              res={
                lead_type == "ra"
                  ? "Recruiting Agency"
                  : lead_type == "company"
                  ? "Company"
                  : lead_type == "applicant"
                  ? "Applicant"
                  : ""
              }
            />
            <ViewTypo title={"Lead Name"} res={lead_type_name} />
            <Grid item xs={6} md={6} lg={6}>
              <MDTypography variant="h6" fontWeight="medium">
                Country
              </MDTypography>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <MDTypography variant="h6" fontWeight="regular">
                <MDAvatar
                  src={`https://flagcdn.com/w40/${country_code?.toLowerCase()}.png`}
                  name={country_name}
                  size="md"
                  style={{ display: "inline-block", overflow: "visible" }}
                  sx={{ width: 20, height: 20 }}
                />{" "}
                {country_name}
              </MDTypography>
            </Grid>

            <ViewTypo title={"Industry"} res={industry_value} />
            <ViewTypo title={"Priority"} res={priority} />
            <ViewTypo
              title={"Assigned To"}
              res={assign_user_first_name + " " + assign_user_last_name}
            />
            <ViewTypo
              title={"Start Date"}
              res={start_date ? dateFormat(start_date) : ""}
            />
            <ViewTypo
              title={"End Date"}
              res={end_date ? dateFormat(end_date) : ""}
            />

            {lead_type === "company" && (
              <>
                <ViewTypo
                  title={"Size of Company"}
                  res={size_of_company_value}
                />
                <ViewTypo title={"Website"} res={website} />
                <ViewTypo
                  title={"Contact Number"}
                  res={contact_code_dial_code + " " + contact_number}
                />
                <ViewTypo title={"E-Mail"} res={email_id} />
              </>
            )}
            {lead_type === "applicant" && (
              <ViewTypo title={"Position"} res={position_value} />
            )}
            <ViewTypo title={"Status"} res={status_value} />
            <ViewTypo title={"Remarks"} res={remarks} />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseDialog}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
    );
  };

  const HeadTypo = ({ title, lg }) => {
    return (
      <Grid item xs={4} md={4} lg={lg}>
        <MDTypography fontSize={pxToRem(14)} fontWeight="medium">
          {title}
        </MDTypography>
      </Grid>
    );
  };

  const BodyTypo = ({ title, lg }) => {
    return (
      <Grid item xs={4} md={4} lg={lg}>
        <MDTypography fontSize={pxToRem(14)} fontWeight="regular">
          {title}
        </MDTypography>
      </Grid>
    );
  };

  const ViewTypo = ({ title, res }) => {
    return (
      <>
        <Grid item xs={6} md={6} lg={6}>
          <MDTypography variant="h6" fontWeight="medium">
            {title}
          </MDTypography>
        </Grid>
        <Grid item xs={6} md={6} lg={6}>
          <MDTypography variant="h6" fontWeight="regular">
            {res}
          </MDTypography>
        </Grid>
      </>
    );
  };

  //START : Search and Filter form data
  //Handle onchange search and filter
  const onChangeSearch = (event) => {
    let name = event.target.name;
    let val = event.target.value;
    setSearchFormData({ ...searchFormData, [name]: val });
  };
  const [csvData, setCsvData] = useState([]);
  const [downloadCsv, setDownloadCsv] = useState(false);
  let csvHeaders = [
    { label: "Lead Type", key: "lead_type" },
    { label: "Lead Name", key: "lead_type_name" },
    { label: "Country", key: "country_name" },
    { label: "Priority", key: "priority" },
    { label: "Status ", key: "status_value" },
    { label: "Assigned To", key: "assigned_to" },
    { label: "Industry", key: "industry_value" },
    { label: "Start Date", key: "start_date" },
    { label: "End Date", key: "end_date" },
  ];
  //Prepare the csv data for export
  const exportCsv = async () => {
    let queryUrl = getQueryParams();
    queryUrl = queryUrl + `&download=1`;
    getFetchData(`leads/list${queryUrl}`).then(async (res) => {
      if (res?.data) {
        let exportData = [];
        await Promise.all(
          res?.data.map((report) => {
            report.lead_type =
              report?.lead_type === "ra"
                ? "Recruiting Agency"
                : firstLetterCapital(report?.lead_type);
            report.assigned_to =
              report?.assign_user_first_name +
              " " +
              report?.assign_user_last_name;
            report.start_date = report?.start_date
              ? dateFormat(report?.start_date)
              : "-";
            report.end_date = report?.end_date
              ? dateFormat(report?.end_date)
              : "-";
            exportData.push(report);
          })
        );
        setCsvData(exportData); //Set the csv data
        setDownloadCsv(true); // Show the CSVDwonload link
        setTimeout(() => {
          csvLink?.current?.link?.click();
          setDownloadCsv(false); // Hide the CSVDwonload link
        }, 100);
      }
    });
  };
  //Prepare the query string for search and filter
  const getQueryParams = () => {
    let queryUrl = `?page=${pageIndex}`;

    queryUrl = searchFormData?.lead_type?.value
      ? queryUrl + "&lead_type=" + searchFormData?.lead_type?.value
      : queryUrl;

    // let leadTypeId =
    //   searchFormData?.lead_type?.value === "ra"
    //     ? searchFormData?.lead_type_id?.ra_id
    //     : searchFormData?.lead_type?.value === "company"
    //     ? searchFormData?.lead_type_id?.company_id
    //     : searchFormData?.lead_type?.value === "applicant"
    //     ? searchFormData?.lead_type_id?.applicant_id
    //     : "";

    queryUrl = searchFormData?.lead_type_name
      ? queryUrl + "&lead_type_name=" + searchFormData?.lead_type_name
      : queryUrl;

    queryUrl = searchFormData?.country_id
      ? queryUrl + "&country_id=" + searchFormData?.country_id?.country_id
      : queryUrl;

    queryUrl = searchFormData?.priority?.value
      ? queryUrl + "&priority=" + searchFormData?.priority?.value
      : queryUrl;

    queryUrl = searchFormData.status_id?.setting_id
      ? queryUrl + "&status_id=" + searchFormData.status_id?.setting_id
      : queryUrl;

    return queryUrl;
  };
  //END : Search and Filter form data

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <CardTopBoxAdd
                title={"Leads"}
                link={"/leaddata"}
                showAddButton={showAddButton}
              />
              <MDBox>
                <Grid container p={2} spacing={2}>
                  {/* START : Search and filter block */}
                  <Grid
                    container
                    className="searchFilterBlock"
                    p={2}
                    spacing={2}
                    mt={1}
                  >
                    <Grid item xs={6} md={2}>
                      <MDBox mb={2} className="mdsAutocompleteGrid">
                        <Autocomplete
                          value={searchFormData.lead_type}
                          size="small"
                          name="lead_type"
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setSearchFormData({
                                ...searchFormData,
                                ["lead_type"]: newValue,
                              });
                            } else {
                              setSearchFormData({
                                ...searchFormData,
                                ["lead_type"]: "",
                              });
                            }
                          }}
                          options={leadOptions}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Lead Type"
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <MDBox mb={2}>
                        <MDInput
                          type="text"
                          name="lead_type_name"
                          label="Lead Name"
                          fullWidth
                          value={searchFormData.lead_type_name}
                          onChange={onChangeSearch}
                        />
                      </MDBox>
                    </Grid>
                    {/* <Grid item xs={6} md={2}>
                      <MDBox mb={2} className="mdsAutocompleteGrid">
                        <Autocomplete
                          value={searchFormData.lead_type_id}
                          size="small"
                          name="lead_type_id"
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setSearchFormData({
                                ...searchFormData,
                                ["lead_type_id"]: newValue,
                              });
                            } else {
                              setSearchFormData({
                                ...searchFormData,
                                ["lead_type_id"]: "",
                              });
                            }
                          }}
                          options={
                            searchFormData.lead_type?.value === "ra"
                              ? raList
                              : searchFormData.lead_type?.value === "applicant"
                              ? applicantList
                              : searchFormData.lead_type?.value === "company"
                              ? companyList
                              : []
                          }
                          getOptionLabel={(paymentOptions) => {
                            if (searchFormData.lead_type?.value === "ra") {
                              return paymentOptions?.ra_name || "";
                            } else if (
                              searchFormData.lead_type?.value === "applicant"
                            ) {
                              return paymentOptions?.first_name
                                ? paymentOptions?.first_name +
                                    " " +
                                    paymentOptions?.last_name
                                : "";
                            } else if (
                              searchFormData.lead_type?.value === "company"
                            ) {
                              return paymentOptions?.name
                                ? paymentOptions?.name
                                : "";
                            } else if (
                              searchFormData.lead_type?.value === "ra"
                            ) {
                              return paymentOptions?.ra_name || "" || "";
                            } else {
                              return "";
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Lead Name"
                              InputLabelProps={{ shrink: true }}
                              onChange={(e) =>
                                searchSpot(
                                  searchFormData.lead_type?.value,
                                  e.target.value
                                )
                              }
                            />
                          )}
                        />
                      </MDBox>
                    </Grid> */}
                    <Grid item xs={6} md={2}>
                      <MDBox mb={2} className="mdsAutocompleteGrid">
                        <Autocomplete
                          value={searchFormData.country_id}
                          size="small"
                          name="country_id"
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setSearchFormData({
                                ...searchFormData,
                                ["country_id"]: newValue,
                              });
                            } else {
                              setSearchFormData({
                                ...searchFormData,
                                ["country_id"]: "",
                              });
                            }
                          }}
                          options={countryList}
                          getOptionLabel={(countryList) =>
                            countryList.name || ""
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Country"
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <MDBox mb={2} className="mdsAutocompleteGrid">
                        <Autocomplete
                          value={searchFormData.priority}
                          size="small"
                          name="priority"
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setSearchFormData({
                                ...searchFormData,
                                ["priority"]: newValue,
                              });
                            } else {
                              setSearchFormData({
                                ...searchFormData,
                                ["priority"]: "",
                              });
                            }
                          }}
                          options={priorityOptions}
                          getOptionLabel={(priorityOptions) =>
                            priorityOptions.label || ""
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Priority"
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <MDBox mb={2} className="mdsAutocompleteGrid">
                        <Autocomplete
                          value={searchFormData.status_id}
                          size="small"
                          name="status_id"
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setSearchFormData({
                                ...searchFormData,
                                ["status_id"]: newValue,
                              });
                            } else {
                              setSearchFormData({
                                ...searchFormData,
                                ["status_id"]: "",
                              });
                            }
                          }}
                          options={statusList}
                          getOptionLabel={(statusList) =>
                            statusList.setting_value || ""
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Status"
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <MDBox display="flex" alignItems="center">
                        {/* {downloadCsv && (
                          <>
                            <CSVDownload
                              data={csvData}
                              filename={"my-3534564-file.csv"}
                              headers={csvHeaders}
                              target="_self"
                            />
                          </>
                        )} */}
                        <CSVLink
                          className=" d-none "
                          data={csvData}
                          filename={`Leads ${dateTimeStamp()}`}
                          headers={csvHeaders}
                          ref={csvLink}
                          target="_self"
                        />
                        <MDButton
                          variant="gradient"
                          color="primary"
                          size="small"
                          type="button"
                          className="searchButton"
                          onClick={exportCsv}
                        >
                          <DownloadIcon /> Export CSV
                        </MDButton>
                      </MDBox>
                    </Grid>
                  </Grid>
                  {/* END : Search and filter block */}

                  {!pageResize && (
                    <Grid item xs={12} md={12} lg={12}>
                      <MDBox
                        borderRadius="lg"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        p={1}
                        sx={{
                          border: ({ borders: { borderWidth, borderColor } }) =>
                            `${borderWidth[1]} solid ${borderColor}`,
                        }}
                      >
                        <HeadTypo title="Lead Type" lg={2} />
                        <HeadTypo title="Lead Name" lg={2} />
                        <HeadTypo title="Assigned To" lg={2} />
                        <HeadTypo title="Country" lg={2} />
                        <HeadTypo title="Priority" lg={1} />
                        <HeadTypo title="Status" lg={1} />
                        <HeadTypo title="Action" lg={2} />
                      </MDBox>
                    </Grid>
                  )}
                  {List.map((raItem, index) => {
                    let {
                      lead_id,
                      lead_type,
                      lead_type_name,
                      assign_user_first_name,
                      assign_user_last_name,
                      country_name,
                      country_code,
                      priority,
                      status_value,
                    } = raItem;
                    return (
                      <>
                        {!pageResize ? (
                          <Grid item key={lead_id} xs={12} md={12} lg={12}>
                            <MDBox
                              borderRadius="lg"
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                              p={1}
                              sx={{
                                border: ({
                                  borders: { borderWidth, borderColor },
                                }) => `${borderWidth[1]} solid ${borderColor}`,
                              }}
                            >
                              <BodyTypo
                                title={
                                  lead_type == "ra"
                                    ? "Recruiting Agency"
                                    : lead_type == "company"
                                    ? "Company"
                                    : lead_type == "applicant"
                                    ? "Applicant"
                                    : ""
                                }
                                lg={2}
                              />
                              <BodyTypo title={lead_type_name} lg={2} />
                              <BodyTypo
                                title={
                                  assign_user_first_name +
                                  " " +
                                  assign_user_last_name
                                }
                                lg={2}
                              />

                              <Grid item xs={4} md={4} lg={2}>
                                <MDTypography
                                  fontSize={pxToRem(14)}
                                  fontWeight="regular"
                                >
                                  <MDAvatar
                                    src={`https://flagcdn.com/w40/${country_code.toLowerCase()}.png`}
                                    name={country_name}
                                    size="md"
                                    style={{
                                      display: "inline-block",
                                      overflow: "visible",
                                    }}
                                    sx={{ width: 20, height: 20 }}
                                  />{" "}
                                  {country_name}
                                </MDTypography>
                              </Grid>

                              <BodyTypo title={priority} lg={1} />
                              <BodyTypo title={status_value} lg={1} />
                              <Grid item xs={4} md={4} lg={2}>
                                <IconButton
                                  size="small"
                                  disableRipple
                                  color="inherit"
                                  onClick={() => handleOpenDialog(raItem)}
                                >
                                  <RemoveRedEye />
                                </IconButton>
                                {showEditButton && (
                                  <IconButton
                                    size="small"
                                    disableRipple
                                    color="inherit"
                                    onClick={() =>
                                      navigate(`/leaddata/${lead_id}`)
                                    }
                                  >
                                    <Edit />
                                  </IconButton>
                                )}
                                {showDeleteButton && (
                                  <IconButton
                                    size="small"
                                    disableRipple
                                    color="inherit"
                                    onClick={()=>{
                                      handleOpenDeleteDialog(lead_id)
                                    }}
                                  >
                                    <Delete />
                                  </IconButton>
                                )}
                              </Grid>
                            </MDBox>
                          </Grid>
                        ) : (
                          <LeadRes
                            key={lead_id}
                            raItem={raItem}
                            index={index + 1}
                            handleOpenDialog={handleOpenDialog}
                            showEditButton={showEditButton}
                            showDeleteButton={showDeleteButton}
                            handleOpenDeleteDialog={handleOpenDeleteDialog}
                          />
                        )}
                      </>
                    );
                  })}
                </Grid>
                <Grid item xs={12} md={3}>
                  <Stack spacing={2} mb={2} ml={2}>
                    <Pagination
                      count={pageCount}
                      page={pageIndex}
                      onChange={doPaginationChange}
                      variant="outlined"
                    />
                  </Stack>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <ViewMoreRa
        openDialog={openDialog}
        raData={raData}
        handleCloseDialog={handleCloseDialog}
      />
      <DeleteLead
      lead_id ={raData.lead_id}
      handleCloseDeleteDialog={handleCloseDeleteDialog}
      openDialogForDelete={openDialogForDelete}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default LeadsList;
