// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { fetchData, deleteData } from "utils/clientFunctions";
import useSWR from "swr";
import { useEffect, useRef, useState } from "react";
import { Token } from "utils/clientFunctions";
import MDButton from "components/MDButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  Tooltip,
} from "@mui/material";
import { settingDefault } from "utils/clientFunctions";
import { ErrorMessage, Field, Form, Formik } from "formik";
import MDInput from "components/MDInput";
import { Link, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useToasts } from "react-toast-notifications";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MDAvatar from "components/MDAvatar";
import { Delete, Edit, RemoveRedEye, TramSharp } from "@mui/icons-material";
import Bill from "../components/Bill";
import { useAuth } from "utils/auth";
import pxToRem from "assets/theme/functions/pxToRem";
import { CardTopBoxAdd } from "layouts/commonComponents/cardTopBoxAdd";
import { dateFormat } from "utils/clientFunctions";

import { useSelector, useDispatch } from "react-redux";
import { getPermissions, selectPermissions } from "reducers/permissionSlice";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { CSVDownload, CSVLink } from "react-csv";
import DownloadIcon from "@mui/icons-material/Download";
import { dateTimeStamp } from "utils/clientFunctions";
import profile_pic_image from "assets/images/profile_picture.png";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function RaList() {
  const csvLink = useRef();
  const [pageIndex, setPageIndex] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [pageResize, setPageResize] = useState(
    window.innerWidth < 992 ? true : false
  );
  const navigate = useNavigate();
  const { addToast } = useToasts();
  //Start : For permission
  const dispatch = useDispatch();
  var permissions = useSelector(selectPermissions);
  if (
    !(permissions?.user_id === 1 || permissions?.permission?.includes("raView"))
  ) {
    navigate("/dashboard");
  }
  let showAddButton =
    permissions?.user_id === 1 || permissions?.permission?.includes("raCreate")
      ? true
      : false;
  let showEditButton =
    permissions?.user_id === 1 || permissions?.permission?.includes("raEdit")
      ? true
      : false;
  let showDeleteButton =
    permissions?.user_id === 1 || permissions?.permission?.includes("raDelete")
      ? true
      : false;
  useEffect(() => {
    dispatch(getPermissions());
  }, []);
  //End : For permission

  const [raList, setRaList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [industries, setIndustries] = useState([]);
  //View More RA data
  const [raData, setRaData] = useState({});
  const { logout } = useAuth();

  const initialLoadData = async () => {
    let queryUrl = getQueryParams();
    const url = `recruiting_agency/list${queryUrl}`;
    const data = await fetchData(url);
    if (
      data?.name === "JsonWebTokenError" ||
      data?.name === "TokenExpiredError" ||
      data?.message === "You are not authorized"
    ) {
      logout();
    }
    if (data && data.data) {
      let raCount = Math.ceil(data.count / 10);
      setPageCount(raCount);
      setRaList(data.data || []);
    }
  };

  //Search & Filter initial data
  const [searchFormData, setSearchFormData] = useState({
    supply_country_id: "",
    ra_code: "",
    ra_name: "",
    industry_id: "",
  });

  useEffect(() => {
    initialLoadData();
  }, [
    pageIndex,
    searchFormData.supply_country_id,
    searchFormData.ra_code,
    searchFormData.ra_name,
    searchFormData.industry_id,
  ]);

  useEffect(() => {
    //Get countries
    const countryUrl = `country/list`;
    getFetchData(countryUrl).then((res) => {
      setCountryList(res.data);
    });

    //Get industries
    const industryUrl = `setting/type/2`;
    getFetchData(industryUrl).then((res) => {
      let industryList = res.data;
      setIndustries(industryList);
    });
  }, []);

  const doPaginationChange = async (event, value) => {
    setPageIndex(value);
  };
  const getFetchData = async (settingUrl) => {
    return await fetchData(settingUrl);
  };
  //Dialog popup
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogForDelete, setDialogForDelete] = useState(false) // Dialog for deleting the recruting_agency
  const [selectedRecruitingId, setSelectedRecruitingId] = useState(null);   // state to store recruitng id
  const handleOpenDialog = (raInfo) => {
    setRaData(raInfo);
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setRaData({});
    setOpenDialog(false);
  };

  const handleOpenDeleteDialog = (ra_id) => {
    setDialogForDelete(true);
    //console.log("RA Id to delete", ra_id);
    setSelectedRecruitingId(ra_id);
  }

  const handleCloseDeleteDialog = () => {
    setDialogForDelete(false);
    setSelectedRecruitingId(null) // Clear the ra Id
  }

  window.addEventListener("resize", () => {
    if (window.innerWidth < 992) {
      setPageResize(true);
    } else {
      setPageResize(false);
    }
  });

  const displayTypoInfo = (label, value) => {
    return (
      <>
        <Grid item xs={6} md={6} lg={6}>
          <MDTypography variant="h6" fontWeight="medium">
            {label}
          </MDTypography>
        </Grid>
        <Grid item xs={6} md={6} lg={6}>
          <MDTypography variant="h6" fontWeight="regular">
            {value}
          </MDTypography>
        </Grid>
      </>
    );
  };

  const getCountryFlag = (countryInfo) => {
    return (
      <>
        <MDTypography variant="h6" fontWeight="regular">
          <MDAvatar
            src={`https://flagcdn.com/w40/${countryInfo?.code.toLowerCase()}.png`}
            name={countryInfo?.name}
            size="md"
            style={{ display: "inline-block", overflow: "visible" }}
            sx={{ width: 20, height: 20 }}
          />{" "}
          {countryInfo?.name}
        </MDTypography>
      </>
    );
  };

  const DeleteRecruitment = ({openDialogForDelete, handleCloseDeleteDialog}) => {
    const handleConfirmDelete = async () => {
      if(!selectedRecruitingId) {
        console.error("No Recruitment selected for deletion");
        return;
      }
      //Close the dialog immediately
      setDialogForDelete(false);

      //Add logic to handle Ra deletion
      const url = `recruiting_agency/${selectedRecruitingId}`
      try {
        const response = await deleteData(url);
        if (response?.status){
          //Success handling
          addToast("Recruiting Agency deleted successfully", {
            appearance: "success",
            autoDismiss: true,
          })
        
        //Refresh the Recruitment list
        setRaList((prevList) => prevList.filter((ra) =>ra.ra_id !== selectedRecruitingId));
        
        //Reset the selected Recruitment id
        setSelectedRecruitingId(null)
        }
        else{
           // Handle errors
           if (response?.message) {
            addToast(response.message, { appearance:"error", autoDismiss: true});
          }
          if (response?.errors) {
            response.errors.forEach((error) => {
              let errorMessage = error.message
                .replace(/['"]+/g, "")
                .replace(/_/g, " ");
              errorMessage =
                errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
              addToast(errorMessage, { appearance: "error", autoDismiss: true });
            });
          }
        }
      } catch (error) {
        console.error("Error deleting Recruitment Agency", error);
        addToast(error, {
          appearance: "error",
          autoDismiss: true,
        })
      }
    }

    return(<>
    <BootstrapDialog
    onClose={handleCloseDeleteDialog}
    aria-labelledby="customized-dialog-title"
    open ={openDialogForDelete}
    fullScreen={false} >
    <DialogTitle sx={{ m: 0 }} id="customized-dialog-title">
         {"Delete Recruiting Agency"}
     </DialogTitle>
     <IconButton
       aria-label="close"
       onClick={handleCloseDeleteDialog}
       sx={{
         position: "absolute",
         right: 8,
         top: 8,
         color: (theme) => theme.palette.grey[500],
       }}
     >
       <CloseIcon />
     </IconButton>
     <DialogContent>
        <MDTypography variant="body1" gutterBottom>
          Are you sure you want to delete this Recruitment?
        </MDTypography>
      </DialogContent>
      <DialogActions>
        <MDButton
          onClick={handleConfirmDelete}
          variant="gradient"
          color="error"
          size="small"
        >
          Delete
        </MDButton>
        <MDButton
          onClick={handleCloseDeleteDialog}
          variant="gradient"
          color="info"
          size="small"
        >
          Cancel
        </MDButton>
      </DialogActions> 
    </BootstrapDialog>
    </>)
  }


  const ViewMoreRa = ({ openDialog, raData, handleCloseDialog }) => {
    const {
      ra_id,
      ra_code,
      ra_name,
      logo,
      licence_number,
      owner_partner_details,
      contact_number,
      landline_number,
      email_id,
      number_of_working_staff,
      industry_id,
      address,
      landmark,
      postal_code,
      city,
      state,
      firm_type,
      owner_partner_list,
      ra_team_list,
      document_list,
    } = raData;

    return (
      <BootstrapDialog
        onClose={handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
        fullScreen={window.innerWidth < 768 ? true : false}
      >
        <DialogTitle sx={{ m: 0 }} id="customized-dialog-title">
          {ra_code}
          <div className="mdsCountryInfo">
            <span>{raData.supply_country?.name}</span>{" "}
            <MDAvatar
              src={`https://flagcdn.com/w40/${raData.supply_country?.code.toLowerCase()}.png`}
              name={raData.supply_country?.name}
              size="md"
              style={{ display: "inline-block", overflow: "visible" }}
              sx={{ width: 20, height: 20 }}
            />
          </div>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid container p={2} spacing={1}>
            {displayTypoInfo("RA Name", ra_name)}
            {displayTypoInfo(
              "Industry Specialised",
              raData.industry_info?.setting_value
            )}
            <Grid item xs={6} md={6} lg={6}>
              <MDTypography variant="h6" fontWeight="medium">
                Logo
              </MDTypography>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <MDAvatar
                src={process.env.REACT_APP_URI_PATH + logo}
                name={ra_name}
                size="md"
                sx={{ width: 56, height: 56 }}
                variant="rounded"
              />
            </Grid>
            {displayTypoInfo("Licence Number", licence_number)}
            {displayTypoInfo(
              "Number of Working Staff",
              number_of_working_staff
            )}
            {displayTypoInfo("Firm Type", firm_type)}
            {displayTypoInfo("Address", address)}
            {displayTypoInfo("Landmark", landmark)}
            {displayTypoInfo("Postal code", postal_code)}
            {displayTypoInfo("City", city)}
            {displayTypoInfo("State", state)}
            {displayTypoInfo("Country", getCountryFlag(raData.country_info))}

            {displayTypoInfo(
              "Landline Number",
              raData.landline_number_code_info?.dial_code +
                " " +
                landline_number
            )}
            <Grid item xs={12} md={12} lg={12}>
              <MDTypography variant="h6" fontWeight="medium">
                {owner_partner_list?.length ? (
                  <MDBox
                    py={1}
                    px={1}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      List of Owner/Partner
                    </MDTypography>
                  </MDBox>
                ) : (
                  ""
                )}
              </MDTypography>
            </Grid>
            {owner_partner_list?.map((user, i) => {
              const {
                name,
                mobile_code_info,
                mobile_number,
                email_id,
                is_lock,
                position_info,
                profile_pic,
                created_by_user_id,
              } = user;
              if (
                permissions?.user_id === 1 ||
                permissions?.user_id === created_by_user_id ||
                !is_lock
              ) {
                return (
                  <>
                    <Grid item xs={12} md={12} lg={12}>
                      <MDTypography variant="h6" fontWeight="medium">
                        {i + 1}.
                      </MDTypography>
                    </Grid>
                    {displayTypoInfo("Name", name)}
                    {displayTypoInfo(
                      "Profile Image",
                      <MDAvatar
                        src={
                          profile_pic
                            ? process.env.REACT_APP_URI_PATH + profile_pic
                            : profile_pic_image
                        }
                        size="md"
                        sx={{ width: 56, height: 56 }}
                        variant="rounded"
                      />
                    )}
                    {displayTypoInfo("Position", position_info?.setting_value)}
                    {displayTypoInfo(
                      "Mobile Number",
                      mobile_code_info?.dial_code + " " + mobile_number
                    )}
                    {displayTypoInfo("E-mail", email_id)}
                    {/* {displayTypoInfo("Lock", is_lock === 1 ? "Yes" : "No")} */}
                  </>
                );
              } else {
                return null;
              }
            })}

            <Grid item xs={12} md={12} lg={12}>
              <MDTypography variant="h6" fontWeight="medium">
                {ra_team_list?.length ? (
                  <MDBox
                    py={1}
                    px={1}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      List of Teams
                    </MDTypography>
                  </MDBox>
                ) : (
                  ""
                )}
              </MDTypography>
            </Grid>
            {ra_team_list?.map((user, i) => {
              const {
                name,
                mobile_code_info,
                mobile_number,
                email_id,
                is_lock,
                position_info,
                nationality_info,
                profile_pic,
                created_by_user_id,
              } = user;
              if (
                permissions?.user_id === 1 ||
                permissions?.user_id === created_by_user_id ||
                !is_lock
              ) {
                return (
                  <>
                    <Grid item xs={12} md={12} lg={12}>
                      <MDTypography variant="h6" fontWeight="medium">
                        {i + 1}.
                      </MDTypography>
                    </Grid>
                    {displayTypoInfo("Name", name)}
                    {displayTypoInfo(
                      "Profile Image",
                      <MDAvatar
                        src={
                          profile_pic
                            ? process.env.REACT_APP_URI_PATH + profile_pic
                            : profile_pic_image
                        }
                        size="md"
                        sx={{ width: 56, height: 56 }}
                        variant="rounded"
                      />
                    )}
                    {displayTypoInfo("Position", position_info?.setting_value)}
                    {displayTypoInfo(
                      "Mobile Number",
                      mobile_code_info?.dial_code + " " + mobile_number
                    )}
                    {displayTypoInfo(
                      "Nationality",
                      getCountryFlag(nationality_info)
                    )}
                    {displayTypoInfo("E-mail", email_id)}
                    {/* {displayTypoInfo("Lock", is_lock === 1 ? "Yes" : "No")} */}
                  </>
                );
              } else {
                return null;
              }
            })}

            <Grid item xs={12} md={12} lg={12}>
              <MDTypography variant="h6" fontWeight="medium">
                {document_list?.length ? (
                  <MDBox
                    py={1}
                    px={1}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      List of Documents
                    </MDTypography>
                  </MDBox>
                ) : (
                  ""
                )}
              </MDTypography>
            </Grid>
            {document_list?.map((document, i) => {
              const {
                document_name,
                issue_date,
                expiry_date,
                upload_document,
                issue_country_info,
              } = document;
              return (
                <>
                  <Grid item xs={12} md={12} lg={12}>
                    <MDTypography variant="h6" fontWeight="medium">
                      {i + 1}.
                    </MDTypography>
                  </Grid>
                  {displayTypoInfo("Name", document_name)}
                  {displayTypoInfo(
                    "Issue Country",
                    getCountryFlag(issue_country_info)
                  )}
                  {displayTypoInfo("Issue Date", dateFormat(issue_date))}
                  {displayTypoInfo(
                    "Expiry Date",
                    expiry_date ? dateFormat(expiry_date) : "-"
                  )}

                  {displayTypoInfo(
                    "Document",
                    <Link
                      to={`${process.env.REACT_APP_URI_PATH}${upload_document}`}
                      target="_blank"
                    >
                      View Document
                    </Link>
                  )}
                </>
              );
            })}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseDialog}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
    );
  };

  //START : Search and Filter form data
  //Handle onchange search and filter
  const onChangeSearch = (event) => {
    let name = event.target.name;
    let val = event.target.value;
    setSearchFormData({ ...searchFormData, [name]: val });
  };
  const [csvData, setCsvData] = useState([]);
  const [downloadCsv, setDownloadCsv] = useState(false);

  let csvHeaders = [
    { label: "Supply Country", key: "supply_country" },
    { label: "RA Code", key: "ra_code" },
    { label: "RA Name", key: "ra_name" },
    { label: "Industry", key: "industry" },
    { label: "Licence Number", key: "licence_number" },
    { label: "Number of Working Staff", key: "number_of_working_staff" },
    { label: "Landline Number", key: "landline_number" },
    { label: "Firm Type", key: "firm_type" },
    { label: "Address", key: "address" },
    ,
  ];
  //Prepare the csv data for export
  const exportCsv = async () => {
    let queryUrl = getQueryParams();
    queryUrl = queryUrl + `&download=1`;
    getFetchData(`recruiting_agency/list${queryUrl}`).then(async (res) => {
      if (res?.data) {
        let exportData = [];
        await Promise.all(
          res?.data.map((report) => {
            report.supply_country = report?.supply_country?.name;
            report.industry = report?.industry_info?.setting_value;
            report.landline_number =
              report?.landline_number_code_info?.dial_code +
              " " +
              report?.landline_number;
            exportData.push(report);
          })
        );
        setCsvData(exportData); //Set the csv data
        setDownloadCsv(true); // Show the CSVDwonload link
        setTimeout(() => {
          csvLink?.current?.link?.click();
          setDownloadCsv(false); // Hide the CSVDwonload link
        }, 100);
      }
    });
  };
  //Prepare the query string for search and filter
  const getQueryParams = () => {
    let queryUrl = `?page=${pageIndex}`;

    queryUrl = searchFormData?.supply_country_id?.country_id
      ? queryUrl +
        "&supply_country_id=" +
        searchFormData?.supply_country_id?.country_id
      : queryUrl;

    queryUrl = searchFormData?.ra_name
      ? queryUrl + "&ra_name=" + searchFormData?.ra_name
      : queryUrl;

    queryUrl = searchFormData.industry_id?.setting_id
      ? queryUrl + "&industry_id=" + searchFormData.industry_id?.setting_id
      : queryUrl;

    queryUrl = searchFormData?.ra_code
      ? queryUrl + "&ra_code=" + searchFormData?.ra_code
      : queryUrl;

    return queryUrl;
  };
  //END : Search and Filter form data

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <CardTopBoxAdd
                title={"Recruiting Agency"}
                link={"/create_ra"}
                showAddButton={showAddButton}
              />
              <MDBox>
                <Grid container p={2} spacing={2}>
                  {/* START : Search and filter block */}
                  <Grid
                    container
                    className="searchFilterBlock"
                    p={2}
                    spacing={2}
                    mt={1}
                  >
                    <Grid item xs={6} md={2}>
                      <MDBox mb={2} className="mdsAutocompleteGrid">
                        <Autocomplete
                          value={searchFormData.supply_country_id}
                          size="small"
                          name="supply_country_id"
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setSearchFormData({
                                ...searchFormData,
                                ["supply_country_id"]: newValue,
                              });
                            } else {
                              setSearchFormData({
                                ...searchFormData,
                                ["supply_country_id"]: "",
                              });
                            }
                          }}
                          options={countryList}
                          getOptionLabel={(countryList) =>
                            countryList.name || ""
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Country"
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <MDBox mb={2}>
                        <MDInput
                          name="ra_name"
                          type="text"
                          label="Name"
                          fullWidth
                          value={searchFormData.ra_name}
                          onChange={onChangeSearch}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <MDBox mb={2}>
                        <MDInput
                          name="ra_code"
                          type="text"
                          label="RA Code"
                          fullWidth
                          value={searchFormData.ra_code}
                          onChange={onChangeSearch}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <MDBox className="mdsAutocompleteGrid">
                        <Autocomplete
                          value={searchFormData.industry_id}
                          size="small"
                          name="industry_id"
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setSearchFormData({
                                ...searchFormData,
                                ["industry_id"]: newValue,
                              });
                            } else {
                              setSearchFormData({
                                ...searchFormData,
                                ["industry_id"]: "",
                              });
                            }
                          }}
                          options={industries}
                          getOptionLabel={(industries) =>
                            industries.setting_value || ""
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Industry Specialised"
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <MDBox display="flex" alignItems="center">
                        {/* {downloadCsv && (
                          <>
                            <CSVDownload
                              data={csvData}
                              filename={"my-3534564-file.csv"}
                              headers={csvHeaders}
                              target="_self"
                            />
                          </>
                        )} */}
                        <CSVLink
                          className=" d-none "
                          data={csvData}
                          filename={`Recruiting Agency ${dateTimeStamp()}`}
                          headers={csvHeaders}
                          ref={csvLink}
                          target="_self"
                        />
                        <MDButton
                          variant="gradient"
                          color="primary"
                          size="small"
                          type="button"
                          className="searchButton"
                          onClick={exportCsv}
                        >
                          <DownloadIcon /> Export CSV
                        </MDButton>
                      </MDBox>
                    </Grid>
                  </Grid>
                  {/* END : Search and filter block */}

                  {!pageResize && (
                    <Grid item xs={12} md={12} lg={12}>
                      <MDBox
                        borderRadius="lg"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        p={1}
                        sx={{
                          border: ({ borders: { borderWidth, borderColor } }) =>
                            `${borderWidth[1]} solid ${borderColor}`,
                        }}
                      >
                        <Grid item xs={4} md={4} lg={2}>
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="medium"
                          >
                            Country
                          </MDTypography>
                        </Grid>
                        <Grid item xs={4} md={4} lg={1}>
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="medium"
                          >
                            Logo
                          </MDTypography>
                        </Grid>
                        <Grid item xs={4} md={4} lg={1}>
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="medium"
                          >
                            RA Code
                          </MDTypography>
                        </Grid>
                        <Grid item xs={4} md={4} lg={2}>
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="medium"
                          >
                            RA Name
                          </MDTypography>
                        </Grid>
                        <Grid item xs={4} md={4} lg={2}>
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="medium"
                          >
                            Industry
                          </MDTypography>
                        </Grid>
                        <Grid item xs={4} md={4} lg={2}>
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="medium"
                          >
                            Contact No.
                          </MDTypography>
                        </Grid>
                        <Grid item xs={4} md={4} lg={1}>
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="medium"
                          >
                            Action
                          </MDTypography>
                        </Grid>
                      </MDBox>
                    </Grid>
                  )}
                  {raList.map((raItem, index) => {
                    let {
                      ra_id,
                      ra_code,
                      ra_name,
                      email_id,
                      logo,
                      contact_number,
                      receiving_country,
                      licence_number,
                      landline_number,
                      landline_number_code_info,
                      industry_info,
                      supply_country,
                    } = raItem;
                    return (
                      <>
                        {!pageResize ? (
                          <Grid item key={ra_id} xs={12} md={12} lg={12}>
                            <MDBox
                              borderRadius="lg"
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                              p={1}
                              sx={{
                                border: ({
                                  borders: { borderWidth, borderColor },
                                }) => `${borderWidth[1]} solid ${borderColor}`,
                              }}
                            >
                              <Grid item xs={4} md={4} lg={2}>
                                <MDTypography
                                  fontSize={pxToRem(14)}
                                  fontWeight="regular"
                                >
                                  <MDAvatar
                                    src={`https://flagcdn.com/w40/${supply_country?.code.toLowerCase()}.png`}
                                    name={supply_country?.name}
                                    size="md"
                                    style={{
                                      display: "inline-block",
                                      overflow: "visible",
                                    }}
                                    sx={{ width: 20, height: 20 }}
                                  />{" "}
                                  {supply_country?.name}
                                </MDTypography>
                              </Grid>
                              <Grid item xs={4} md={4} lg={1}>
                                <MDAvatar
                                  src={process.env.REACT_APP_URI_PATH + logo}
                                  name={ra_name}
                                  size="md"
                                  sx={{ width: 56, height: 56 }}
                                  variant="rounded"
                                />
                              </Grid>
                              <Grid item xs={4} md={4} lg={1}>
                                <MDTypography
                                  fontSize={pxToRem(14)}
                                  fontWeight="regular"
                                >
                                  {ra_code}
                                </MDTypography>
                              </Grid>

                              <Grid item xs={4} md={4} lg={2}>
                                <MDTypography
                                  fontSize={pxToRem(14)}
                                  fontWeight="regular"
                                >
                                  {ra_name}
                                </MDTypography>
                              </Grid>
                              <Grid item xs={4} md={4} lg={2}>
                                <MDTypography
                                  fontSize={pxToRem(14)}
                                  fontWeight="regular"
                                >
                                  {industry_info?.setting_value}
                                </MDTypography>
                              </Grid>
                              <Grid item xs={4} md={4} lg={2}>
                                <MDTypography
                                  fontSize={pxToRem(14)}
                                  fontWeight="regular"
                                >
                                  {landline_number_code_info?.dial_code +
                                    " " +
                                    landline_number}
                                </MDTypography>
                              </Grid>
                              <Grid item xs={4} md={4} lg={1}>
                                <IconButton
                                  size="small"
                                  disableRipple
                                  color="inherit"
                                  onClick={() => handleOpenDialog(raItem)}
                                >
                                  <RemoveRedEye />
                                </IconButton>
                                {showEditButton && (
                                  <IconButton
                                    size="small"
                                    disableRipple
                                    color="inherit"
                                    onClick={() =>
                                      navigate(`/create_ra/${ra_id}`)
                                    }
                                  >
                                    <Edit />
                                  </IconButton>
                                )}
                                {showDeleteButton && (
                                  <IconButton
                                    size="small"
                                    disableRipple
                                    color="inherit"
                                    onClick={()=>{
                                      handleOpenDeleteDialog(ra_id)
                                    }}
                                  >
                                    <Delete />
                                  </IconButton>
                                )}
                              </Grid>
                            </MDBox>
                          </Grid>
                        ) : (
                          <Bill
                            key={ra_id}
                            raItem={raItem}
                            index={index + 1}
                            handleOpenDialog={handleOpenDialog}
                            showEditButton={showEditButton}
                            showDeleteButton={showDeleteButton}
                            handleOpenDeleteDialog={handleOpenDeleteDialog}
                          />
                        )}
                      </>
                    );
                  })}
                </Grid>
                <Grid item xs={12} md={3}>
                  <Stack spacing={2} mb={2} ml={2}>
                    <Pagination
                      count={pageCount}
                      page={pageIndex}
                      onChange={doPaginationChange}
                      variant="outlined"
                    />
                  </Stack>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <ViewMoreRa
        openDialog={openDialog}
        raData={raData}
        handleCloseDialog={handleCloseDialog}
      />
      <DeleteRecruitment
      ra_id = {raData.ra_id}
      handleCloseDeleteDialog={handleCloseDeleteDialog}
      openDialogForDelete={openDialogForDelete} />
      <Footer />
    </DashboardLayout>
  );
}

export default RaList;
