// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { fetchData, deleteData } from "utils/clientFunctions";
import { useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { Delete, Edit, RemoveRedEye } from "@mui/icons-material";
import { useAuth } from "utils/auth";
import pxToRem from "assets/theme/functions/pxToRem";
import { CardTopBoxAdd } from "layouts/commonComponents/cardTopBoxAdd";
import StaffRes from "../components/staffRes";
import { useSelector, useDispatch } from "react-redux";
import { getPermissions, selectPermissions } from "reducers/permissionSlice";
import { firstLetterCapital } from "utils/clientFunctions";
import { liveSearchData } from "layouts/commonComponents/liveSearchData";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { CSVDownload, CSVLink } from "react-csv";
import DownloadIcon from "@mui/icons-material/Download";
import { dateTimeStamp } from "utils/clientFunctions";
import profile_pic_image from "assets/images/profile_picture.png";
import MDAvatar from "components/MDAvatar";
import { useToasts } from "react-toast-notifications";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function StaffsList() {
  const csvLink = useRef();
  const [pageIndex, setPageIndex] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [pageResize, setPageResize] = useState(
    window.innerWidth < 992 ? true : false
  );
  const navigate = useNavigate();
  const { addToast } = useToasts();
  //Start : For permission
  const dispatch = useDispatch();
  var permissions = useSelector(selectPermissions);
  if (
    !(
      permissions?.user_id === 1 || permissions?.permission?.includes("usrView")
    )
  ) {
    navigate("/dashboard");
  }
  let showAddButton =
    permissions?.user_id === 1 || permissions?.permission?.includes("usrCreate")
      ? true
      : false;
  let showEditButton =
    permissions?.user_id === 1 || permissions?.permission?.includes("usrEdit")
      ? true
      : false;
  let showDeleteButton =
    permissions?.user_id === 1 || permissions?.permission?.includes("usrDelete")
      ? true
      : false;
  useEffect(() => {
    dispatch(getPermissions());
  }, []);
  //End : For permission

  const [List, setList] = useState([]);
  const [raData, setRaData] = useState({});
  const { logout } = useAuth();

  const initialLoadData = async () => {
    let queryUrl = getQueryParams();
    const url = `user/list${queryUrl}`;
    const data = await fetchData(url);
    if (
      data?.name === "JsonWebTokenError" ||
      data?.name === "TokenExpiredError" ||
      data?.message === "You are not authorized"
    ) {
      logout();
    }
    if (data && data.data) {
      setPageCount(Math.ceil(data.count / 10));
      setList(data.data || []);
    }
  };
  //Search & Filter initial data
  const [searchFormData, setSearchFormData] = useState({
    user_type: "",
    name: "",
    mobile_number: "",
    email_id: "",
  });
  const getFetchData = async (settingUrl) => {
    return await fetchData(settingUrl);
  };
  useEffect(() => {
    initialLoadData();
  }, [
    pageIndex,
    searchFormData.user_type,
    searchFormData.name,
    searchFormData.mobile_number,
    searchFormData.email_id,
  ]);

  const doPaginationChange = (event, value) => {
    setPageIndex(value);
  };

  //Dialog popup
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogForDelete, setDialogForDelete] = useState(false) // Dialog for deleting the user
  const [selectedUserId, setSelectedUserId] = useState(null);   // state to store user id
  const handleOpenDialog = (raInfo) => {
    setRaData(raInfo);
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setRaData({});
    setOpenDialog(false);
  };

  const handleOpenDeleteDialog = (user_id) =>{
    setDialogForDelete(true);
   //console.log("User Id to delete", user_id);
    setSelectedUserId(user_id);
  }

  const handleCloseDeleteDialog = () =>{
    setDialogForDelete(false);
    setSelectedUserId(null) //Clear the user Id
  }

  const DeleteUser = ({openDialogForDelete, handleCloseDeleteDialog}) => {

    const handleConfirmDelete = async () => {
      if(!selectedUserId) {
        console.error("No user selected for deletion");
        return;
      }

      
      //Close the dialog immediately
      setDialogForDelete(false);

      //Add logic to handle user deletion
      const url = `user/${selectedUserId}`;
      try {
        const response = await deleteData(url);
        if(response?.status) {
          // Success handling
          addToast("User deleted successfully", {
            appearance: "success",
            autoDismiss: true,
          });

          // Refresh the user list
          setList((prevList) => prevList.filter((user) => user.user_id !== selectedUserId));

           //Reset the selected interview ID
           setSelectedUserId(null);
        } else {
          // Handle errors
          if (response?.message) {
            addToast(response.message, { appearance:"error", autoDismiss: true});
          }
          if (response?.errors) {
            response.errors.forEach((error) => {
              let errorMessage = error.message
                .replace(/['"]+/g, "")
                .replace(/_/g, " ");
              errorMessage =
                errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
              addToast(errorMessage, { appearance: "error", autoDismiss: true });
            });
          }
        }
        
      } catch (error) {
        console.error("Error deleting user", error);
        addToast(error, {
          appearance: "error",
          autoDismiss: true,
        });
      }
    }

    return (<>
      <BootstrapDialog
        onClose={handleCloseDeleteDialog}
        aria-labelledby="customized-dialog-title"
        open={openDialogForDelete}
        fullScreen={false}
      >
        <DialogTitle sx={{ m: 0 }} id="customized-dialog-title">
          {"Delete User"}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleCloseDeleteDialog}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <MDTypography variant="body1" gutterBottom>
          Are you sure you want to delete this User?
        </MDTypography>
      </DialogContent>
      <DialogActions>
        <MDButton
          onClick={handleConfirmDelete}
          variant="gradient"
          color="error"
          size="small"
        >
          Delete
        </MDButton>
        <MDButton
          onClick={handleCloseDeleteDialog}
          variant="gradient"
          color="info"
          size="small"
        >
          Cancel
        </MDButton>
      </DialogActions> 
      </BootstrapDialog>
    </>)

  }

  window.addEventListener("resize", () => {
    if (window.innerWidth < 992) {
      setPageResize(true);
    } else {
      setPageResize(false);
    }
  });

  const ViewMoreRa = ({ openDialog, raData, handleCloseDialog }) => {
    const {
      user_type,
      user_id,
      first_name,
      last_name,
      mobile_number,
      profile_pic,
      email_id,
      role_info,
    } = raData;

    return (
      <BootstrapDialog
        onClose={handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
        fullScreen={window.innerWidth < 768 ? true : false}
      >
        <DialogTitle sx={{ m: 0 }} id="customized-dialog-title">
          {"User Details"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid container px={2} spacing={1}>
            <ViewTypo
              title={"User Type"}
              res={
                user_id === 1 ? "Super Admin" : firstLetterCapital(user_type)
              }
            />
            <ViewTypo
              title={"Profile Image"}
              res={
                <MDAvatar
                  src={
                    profile_pic
                      ? process.env.REACT_APP_URI_PATH + profile_pic
                      : profile_pic_image
                  }
                  size="md"
                  sx={{ width: 56, height: 56 }}
                  variant="rounded"
                />
              }
            />
            <ViewTypo title={"First Name"} res={first_name} />
            <ViewTypo title={"Last Name"} res={last_name} />
            {/* <ViewTypo title={"Role"} res={role_info?.name} /> */}
            <ViewTypo title={"Mobile No."} res={mobile_number} />
            <ViewTypo title={"Email"} res={email_id} />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseDialog}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
    );
  };

  const HeadTypo = ({ title, lg }) => {
    return (
      <Grid item xs={4} md={4} lg={lg}>
        <MDTypography fontSize={pxToRem(14)} fontWeight="medium">
          {title}
        </MDTypography>
      </Grid>
    );
  };

  const BodyTypo = ({ title, lg }) => {
    return (
      <Grid item xs={4} md={4} lg={lg}>
        <MDTypography fontSize={pxToRem(14)} fontWeight="regular">
          {title}
        </MDTypography>
      </Grid>
    );
  };

  const ViewTypo = ({ title, res }) => {
    return (
      <>
        <Grid item xs={6} md={6} lg={6}>
          <MDTypography variant="h6" fontWeight="medium">
            {title}
          </MDTypography>
        </Grid>
        <Grid item xs={6} md={6} lg={6}>
          <MDTypography variant="h6" fontWeight="regular">
            {res}
          </MDTypography>
        </Grid>
      </>
    );
  };

  //START : Search and Filter form data
  //Handle onchange search and filter
  const onChangeSearch = (event) => {
    let name = event.target.name;
    let val = event.target.value;
    setSearchFormData({ ...searchFormData, [name]: val });
  };
  const [csvData, setCsvData] = useState([]);
  const [downloadCsv, setDownloadCsv] = useState(false);

  let csvHeaders = [
    { label: "User Type", key: "user_type" },
    { label: "First Name", key: "first_name" },
    { label: "Last Name", key: "last_name" },
    { label: "Mobile Number", key: "mobile_number" },
    { label: "Email", key: "email_id" },
  ];
  //Prepare the csv data for export
  const exportCsv = async () => {
    let queryUrl = getQueryParams();
    queryUrl = queryUrl + `&download=1`;
    getFetchData(`user/list${queryUrl}`).then(async (res) => {
      if (res?.data) {
        let exportData = [];
        await Promise.all(
          res?.data.map((report) => {
            report.user_type =
              report?.user_id === 1
                ? "Super Admin"
                : report?.user_type
                ? firstLetterCapital(report?.user_type)
                : "";
            exportData.push(report);
          })
        );
        setCsvData(exportData); //Set the csv data
        setDownloadCsv(true); // Show the CSVDwonload link
        setTimeout(() => {
          csvLink?.current?.link?.click();
          setDownloadCsv(false); // Hide the CSVDwonload link
        }, 100);
      }
    });
  };
  //Prepare the query string for search and filter
  const getQueryParams = () => {
    let queryUrl = `?page=${pageIndex}`;

    queryUrl = searchFormData?.user_type?.value
      ? queryUrl + "&user_type=" + searchFormData?.user_type?.value
      : queryUrl;

    queryUrl = searchFormData?.name
      ? queryUrl + "&name=" + searchFormData?.name
      : queryUrl;

    queryUrl = searchFormData?.mobile_number
      ? queryUrl + "&mobile_number=" + searchFormData?.mobile_number
      : queryUrl;

    queryUrl = searchFormData?.email_id
      ? queryUrl + "&email_id=" + searchFormData?.email_id
      : queryUrl;

    return queryUrl;
  };
  //END : Search and Filter form data

  const userTypeData = [
    { label: "Staff", value: "staff" },
    { label: "Partner", value: "partner" },
  ];
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <CardTopBoxAdd
                title={"Users"}
                link={"/staffdata"}
                showAddButton={showAddButton}
              />
              <MDBox>
                <Grid container p={2} spacing={2}>
                  {/* START : Search and filter block */}
                  <Grid
                    container
                    className="searchFilterBlock"
                    p={2}
                    spacing={2}
                    mt={1}
                  >
                    <Grid item xs={6} md={2}>
                      <MDBox mb={2} className="mdsAutocompleteGrid">
                        <Autocomplete
                          value={searchFormData.user_type}
                          size="small"
                          name="user_type"
                          options={userTypeData}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setSearchFormData({
                                ...searchFormData,
                                ["user_type"]: newValue,
                              });
                            } else {
                              setSearchFormData({
                                ...searchFormData,
                                ["user_type"]: "",
                              });
                            }
                          }}
                          getOptionLabel={(userTypeData) =>
                            userTypeData.label || ""
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="User Type"
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                      </MDBox>
                    </Grid>

                    <Grid item xs={6} md={2}>
                      <MDBox mb={2}>
                        <MDInput
                          name="name"
                          type="text"
                          label="Name"
                          fullWidth
                          value={searchFormData.name}
                          onChange={onChangeSearch}
                        />
                      </MDBox>
                    </Grid>

                    <Grid item xs={6} md={2}>
                      <MDBox mb={2}>
                        <MDInput
                          name="mobile_number"
                          type="tel"
                          label="Mobile Number"
                          fullWidth
                          value={searchFormData.mobile_number}
                          onChange={onChangeSearch}
                        />
                      </MDBox>
                    </Grid>

                    <Grid item xs={6} md={2}>
                      <MDBox mb={2}>
                        <MDInput
                          name="email_id"
                          type="email"
                          label="Email ID"
                          fullWidth
                          value={searchFormData.email_id}
                          onChange={onChangeSearch}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <MDBox display="flex" alignItems="center">
                        {/* {downloadCsv && (
                          <>
                            <CSVDownload
                              data={csvData}
                              filename={"my-3534564-file.csv"}
                              headers={csvHeaders}
                              target="_self"
                            />
                          </>
                        )} */}
                        <CSVLink
                          className=" d-none "
                          data={csvData}
                          filename={`Users ${dateTimeStamp()}`}
                          headers={csvHeaders}
                          ref={csvLink}
                          target="_self"
                        />
                        <MDButton
                          variant="gradient"
                          color="primary"
                          size="small"
                          type="button"
                          className="searchButton"
                          onClick={exportCsv}
                        >
                          <DownloadIcon /> Export CSV
                        </MDButton>
                      </MDBox>
                    </Grid>
                  </Grid>
                  {/* END : Search and filter block */}

                  {!pageResize && (
                    <Grid item xs={12} md={12} lg={12}>
                      <MDBox
                        borderRadius="lg"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        p={1}
                        sx={{
                          border: ({ borders: { borderWidth, borderColor } }) =>
                            `${borderWidth[1]} solid ${borderColor}`,
                        }}
                      >
                        <HeadTypo title="User Type" lg={1} />
                        <HeadTypo title="Pic" lg={1} />
                        <HeadTypo title="First Name" lg={2} />
                        <HeadTypo title="Last Name" lg={2} />
                        {/* <HeadTypo title="Role" lg={2} /> */}
                        <HeadTypo title="Mobile No." lg={2} />
                        <HeadTypo title="Email" lg={3} />
                        <HeadTypo title="Action" lg={1} />
                      </MDBox>
                    </Grid>
                  )}
                  {List.map((raItem, index) => {
                    let {
                      user_type,
                      user_id,
                      first_name,
                      last_name,
                      mobile_number,
                      email_id,
                      profile_pic,
                      role_info,
                    } = raItem;

                    return (
                      <>
                        {!pageResize ? (
                          <Grid item key={user_id} xs={12} md={12} lg={12}>
                            <MDBox
                              borderRadius="lg"
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                              p={1}
                              sx={{
                                border: ({
                                  borders: { borderWidth, borderColor },
                                }) => `${borderWidth[1]} solid ${borderColor}`,
                              }}
                            >
                              <BodyTypo
                                title={
                                  user_id === 1
                                    ? "Super Admin"
                                    : firstLetterCapital(user_type)
                                }
                                lg={1}
                              />
                              <BodyTypo
                                title={
                                  <MDAvatar
                                    src={
                                      profile_pic
                                        ? process.env.REACT_APP_URI_PATH +
                                          profile_pic
                                        : profile_pic_image
                                    }
                                    size="md"
                                    sx={{ width: 56, height: 56 }}
                                    variant="rounded"
                                  />
                                }
                                lg={1}
                              />
                              <BodyTypo title={first_name} lg={2} />
                              <BodyTypo title={last_name} lg={2} />

                              {/* <BodyTypo title={role_info?.name} lg={2} /> */}
                              <BodyTypo title={mobile_number} lg={2} />
                              <BodyTypo title={email_id} lg={3} />
                              <Grid item xs={4} md={4} lg={1}>
                                <IconButton
                                  size="small"
                                  disableRipple
                                  color="inherit"
                                  onClick={() => handleOpenDialog(raItem)}
                                >
                                  <RemoveRedEye />
                                </IconButton>
                                {user_id !== 1 && (
                                  <>
                                    {showEditButton && (
                                      <IconButton
                                        size="small"
                                        disableRipple
                                        color="inherit"
                                        onClick={() =>
                                          navigate(`/staffdata/${user_id}`)
                                        }
                                      >
                                        <Edit />
                                      </IconButton>
                                    )}
                                    {showDeleteButton && (
                                      <IconButton
                                        size="small"
                                        disableRipple
                                        color="inherit"
                                        onClick={()=>{
                                          handleOpenDeleteDialog(user_id)
                                        }}
                                      >
                                        <Delete />
                                      </IconButton>
                                    )}
                                  </>
                                )}
                              </Grid>
                            </MDBox>
                          </Grid>
                        ) : (
                          <StaffRes
                            key={user_id}
                            raItem={raItem}
                            index={index + 1}
                            handleOpenDialog={handleOpenDialog}
                            showEditButton={showEditButton}
                            showDeleteButton={showDeleteButton}
                            handleOpenDeleteDialog={handleOpenDeleteDialog}
                          />
                        )}
                      </>
                    );
                  })}
                </Grid>
                <Grid item xs={12} md={3}>
                  <Stack spacing={2} mb={2} ml={2}>
                    <Pagination
                      count={pageCount}
                      page={pageIndex}
                      onChange={doPaginationChange}
                      variant="outlined"
                    />
                  </Stack>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <ViewMoreRa
        openDialog={openDialog}
        raData={raData}
        handleCloseDialog={handleCloseDialog}
      />
      <DeleteUser
        user_id ={raData.user_id}
         handleCloseDeleteDialog={handleCloseDeleteDialog}
         openDialogForDelete={openDialogForDelete}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default StaffsList;
