// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { fetchData, deleteData } from "utils/clientFunctions";
import useSWR from "swr";
import { useEffect, useRef, useState } from "react";
import { Token } from "utils/clientFunctions";
import MDButton from "components/MDButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  Tooltip,
} from "@mui/material";
import { settingDefault } from "utils/clientFunctions";
import { ErrorMessage, Field, Form, Formik } from "formik";
import MDInput from "components/MDInput";
import { Link, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useToasts } from "react-toast-notifications";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MDAvatar from "components/MDAvatar";
import { Delete, Edit, RemoveRedEye, TramSharp } from "@mui/icons-material";
import { useAuth } from "utils/auth";
import pxToRem from "assets/theme/functions/pxToRem";
import MobileList from "../components/mobileList";
import { dateFormat } from "utils/clientFunctions";
import { CardTopBoxAdd } from "layouts/commonComponents/cardTopBoxAdd";
import { dateTimeFormat } from "utils/clientFunctions";
import { useSelector, useDispatch } from "react-redux";
import { getPermissions, selectPermissions } from "reducers/permissionSlice";
import { firstLetterCapital } from "utils/clientFunctions";
import { liveSearchData } from "layouts/commonComponents/liveSearchData";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { CSVDownload, CSVLink } from "react-csv";
import DownloadIcon from "@mui/icons-material/Download";
import { dateTimeStamp } from "utils/clientFunctions";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function InterviewList() {
  const csvLink = useRef();
  const [pageIndex, setPageIndex] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [pageResize, setPageResize] = useState(
    window.innerWidth < 992 ? true : false
  );
  const navigate = useNavigate();
  const { addToast } = useToasts();
  //Start : For permission
  const dispatch = useDispatch();
  var permissions = useSelector(selectPermissions);
  if (
    !(
      permissions?.user_id === 1 || permissions?.permission?.includes("intView")
    )
  ) {
    navigate("/dashboard");
  }
  let showAddButton =
    permissions?.user_id === 1 || permissions?.permission?.includes("intCreate")
      ? true
      : false;
  let showEditButton =
    permissions?.user_id === 1 || permissions?.permission?.includes("intEdit")
      ? true
      : false;
  let showDeleteButton =
    permissions?.user_id === 1 || permissions?.permission?.includes("intDelete")
      ? true
      : false;
  useEffect(() => {
    dispatch(getPermissions());
  }, []);
  //End : For permission

  const [interviewList, setInterviewList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [positionList, setPositionList] = useState([]);
  const [statusList, setStatusList] = useState([]);

  //View More RA data
  const [interviewData, setinterviewData] = useState({});
  const { logout } = useAuth();

  const initialLoadData = async () => {
    let queryUrl = getQueryParams();
    const url = `interview/list${queryUrl}`;
    const data = await fetchData(url);
    if (
      data?.name === "JsonWebTokenError" ||
      data?.name === "TokenExpiredError" ||
      data?.message === "You are not authorized"
    ) {
      logout();
    }
    if (data && data.data) {
      let interviewCount = Math.ceil(data.count / 10);
      setPageCount(interviewCount);
      setInterviewList(data.data || []);
    }
  };

  //Search & Filter initial data
  const [searchFormData, setSearchFormData] = useState({
    area: "",
    interview_date: "",
    company_id: "",
    position_id: "",
    status_id: "",
  });
  const searchSpot = (value, key) => {
    liveSearchData({
      countryId: null,
      value,
      key,
      setCompanyList,
    });
  };

  useEffect(() => {
    initialLoadData();
  }, [
    pageIndex,
    searchFormData.area,
    searchFormData.interview_date,
    searchFormData.company_id,
    searchFormData.position_id,
    searchFormData.status_id,
  ]);

  useEffect(() => {
    const companyUrl = `company/list?page=1`;
    getFetchData(companyUrl).then((res) => {
      setCompanyList(res.data);
    });

    //Get position List
    const positionUrl = `setting/type/4`;
    getFetchData(positionUrl).then((res) => {
      setPositionList(res.data);
    });

    //Get status list
    const statusUrl = `setting/type/8`;
    getFetchData(statusUrl).then((res) => {
      setStatusList(res.data);
    });
  }, []);

  const doPaginationChange = (event, value) => {
    setPageIndex(value);
  };

  //Dialog popup
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogForDelete, setDialogForDelete] = useState(false) // Dialog for deleting the interview
  const [selectedInterviewId, setSelectedInterviewId] = useState(null);   // state to store interview id
  const handleOpenDialog = (callReportInfo) => {
    setinterviewData(callReportInfo);
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setinterviewData({});
    setOpenDialog(false);
  };

  const handleOpenDeleteDialog = (interview_id) =>{
    setDialogForDelete(true);
    //console.log("Interview Id to delete", interview_id);
    setSelectedInterviewId(interview_id);
  }

  const handleCloseDeleteDialog = () =>{
    setDialogForDelete(false);
    setSelectedInterviewId(null) //Clear the interview Id
  }

  window.addEventListener("resize", () => {
    if (window.innerWidth < 992) {
      setPageResize(true);
    } else {
      setPageResize(false);
    }
  });

  const getFetchData = async (settingUrl) => {
    return await fetchData(settingUrl);
  };

  const displayTypoInfo = (label, value) => {
    return (
      <>
        <Grid item xs={6} md={6} lg={6}>
          <MDTypography variant="h6" fontWeight="medium">
            {label}
          </MDTypography>
        </Grid>
        <Grid item xs={6} md={6} lg={6}>
          <MDTypography variant="h6" fontWeight="regular">
            {value}
          </MDTypography>
        </Grid>
      </>
    );
  };

  const DeleteInterview =({openDialogForDelete, handleCloseDeleteDialog}) => {
    const handleConfirmDelete = async () => {
      if (!selectedInterviewId) {
        console.error("No interview selected for deletion");
        return;
      }

      //Close the dialog immediately
      setDialogForDelete(false);

      //Add logic to handle interview deletion
      const url = `interview/${selectedInterviewId}`;
      try {
        const response = await deleteData(url);

        if (response?.status) {
          // Success handling
          addToast("Interview deleted successfully", {
            appearance: "success",
            autoDismiss: true,
          });

          //Refresh the interview list
          setInterviewList((prevList) => prevList.filter((interview) => interview.interview_id !== selectedInterviewId));

          //Reset the selected interview ID
          setSelectedInterviewId(null);
        } else {
          // Handle errors
          if (response?.message) {
            addToast(response.message, { appearance:"error", autoDismiss: true});
          }
          if (response?.errors) {
            response.errors.forEach((error) => {
              let errorMessage = error.message
                .replace(/['"]+/g, "")
                .replace(/_/g, " ");
              errorMessage =
                errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
              addToast(errorMessage, { appearance: "error", autoDismiss: true });
            });
          }
        }

      } catch (error) {
        console.error("Error deleting interview", error);
        addToast(error, {
          appearance: "error",
          autoDismiss: true,
        });
      }

    }
    return(<>
    <BootstrapDialog
      onClose={handleCloseDeleteDialog}
      aria-labelledby="customized-dialog-title"
      open={openDialogForDelete}
      fullScreen={false}
    >
      <DialogTitle sx={{ m: 0 }} id="customized-dialog-title">
          {"Delete Interview"}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleCloseDeleteDialog}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <MDTypography variant="body1" gutterBottom>
          Are you sure you want to delete this Interview?
        </MDTypography>
      </DialogContent>
      <DialogActions>
        <MDButton
          onClick={handleConfirmDelete}
          variant="gradient"
          color="error"
          size="small"
        >
          Delete
        </MDButton>
        <MDButton
          onClick={handleCloseDeleteDialog}
          variant="gradient"
          color="info"
          size="small"
        >
          Cancel
        </MDButton>
      </DialogActions> 
    </BootstrapDialog>
    </>)
  }

  const ViewMoreRa = ({ openDialog, interviewData, handleCloseDialog }) => {
    let {
      area,
      interview_date_time,
      remarks,
      company_info,
      position_info,
      status_info,
      start_date,
      end_date,
      location,
      interviewer,
      total_selections,
      deployed_number,
      unavailable,
      positions_list,
      locations_list,
    } = interviewData;
    return (
      <BootstrapDialog
        onClose={handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
        fullScreen={window.innerWidth < 768 ? true : false}
      >
        <DialogTitle sx={{ m: 0 }} id="customized-dialog-title">
          Interview Details
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid container p={2} spacing={1}>
            {displayTypoInfo("Local/Overseas", area)}
            {/* {displayTypoInfo(
              "Interview Date/Time",
              dateTimeFormat(interview_date_time)
            )} */}
            {displayTypoInfo("Company Name", company_info?.name)}
            {displayTypoInfo(
              "Positions",
              positions_list?.map((position, i) => {
                return (
                  (i != 0 ? ", " : " ") + position?.position_info?.setting_value
                );
              })
            )}
            {displayTypoInfo("Start Date", dateFormat(start_date))}
            {displayTypoInfo("End Date", dateFormat(end_date))}
            {area == "Local Area" && displayTypoInfo("Location", location)}
            {area == "Overseas" &&
              displayTypoInfo(
                "Locations",
                locations_list?.map((location, i) => {
                  return (i != 0 ? ", " : " ") + location?.location;
                })
              )}
            {displayTypoInfo("Status", status_info?.setting_value)}

            {displayTypoInfo("Interviewer", interviewer)}
            {displayTypoInfo("Total Selections", total_selections)}
            {displayTypoInfo("Deployed Number", deployed_number)}
            {displayTypoInfo("Unavailable", unavailable)}
            {displayTypoInfo("Remarks", remarks)}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseDialog}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
    );
  };
  //START : Search and Filter form data
  //Handle onchange search and filter
  const onChangeSearch = (event) => {
    let name = event.target.name;
    let val = event.target.value;
    setSearchFormData({ ...searchFormData, [name]: val });
  };
  const [csvData, setCsvData] = useState([]);
  const [downloadCsv, setDownloadCsv] = useState(false);

  // Local/Overseas
  // Date of Interview
  // Company Name
  // Position
  // Status

  let csvHeaders = [
    { label: "Area", key: "area" },
    { label: "Start Date", key: "start_date" },
    { label: "End Date", key: "end_date" },
    { label: "Company Name", key: "company_name" },
    { label: "Position", key: "position_name" },
    { label: "Status", key: "status" },
    { label: "Total Selections", key: "total_selections" },
    { label: "Deployment Number", key: "deployed_number" },
  ];
  //Prepare the csv data for export
  const exportCsv = async () => {
    let queryUrl = getQueryParams();
    queryUrl = queryUrl + `&download=1`;
    getFetchData(`interview/list${queryUrl}`).then(async (res) => {
      if (res?.data) {
        let exportData = [];
        await Promise.all(
          res?.data.map((report) => {
            // report.interview_date_time = report?.interview_date_time
            //   ? dateTimeFormat(report?.interview_date_time)
            //   : "-";
            report.start_date = report?.start_date
              ? dateFormat(report?.start_date)
              : "-";
            report.end_date = report?.end_date
              ? dateFormat(report?.end_date)
              : "-";
            report.company_name = report?.company_info?.name;
            //report.position_name = report?.position_info?.setting_value;
            report.position_name = report?.positions_list?.map(
              (position, i) => {
                return (
                  (i != 0 ? " " : " ") + position?.position_info?.setting_value
                );
              }
            );
            report.status = report?.status_info?.setting_value;
            exportData.push(report);
          })
        );
        setCsvData(exportData); //Set the csv data
        setDownloadCsv(true); // Show the CSVDwonload link
        setTimeout(() => {
          csvLink?.current?.link?.click();
          setDownloadCsv(false); // Hide the CSVDwonload link
        }, 100);
      }
    });
  };
  //Prepare the query string for search and filter
  const getQueryParams = () => {
    let queryUrl = `?page=${pageIndex}`;

    queryUrl = searchFormData?.area?.value
      ? queryUrl + "&area=" + searchFormData?.area?.value
      : queryUrl;

    queryUrl = searchFormData?.interview_date
      ? queryUrl + "&interview_date=" + searchFormData?.interview_date
      : queryUrl;

    queryUrl = searchFormData?.company_id?.company_id
      ? queryUrl + "&company_id=" + searchFormData?.company_id?.company_id
      : queryUrl;

    queryUrl = searchFormData.position_id?.setting_id
      ? queryUrl + "&position_id=" + searchFormData.position_id?.setting_id
      : queryUrl;

    queryUrl = searchFormData.status_id?.setting_id
      ? queryUrl + "&status_id=" + searchFormData.status_id?.setting_id
      : queryUrl;

    return queryUrl;
  };
  //END : Search and Filter form data

  const areaOptions = [
    { label: "Local Area", value: "Local Area" },
    { label: "Overseas", value: "Overseas" },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <CardTopBoxAdd
                title={"Interviews"}
                link={"/interview"}
                showAddButton={showAddButton}
              />
              <MDBox>
                <Grid container p={2} spacing={2}>
                  {/* START : Search and filter block */}
                  <Grid
                    container
                    className="searchFilterBlock"
                    p={2}
                    spacing={2}
                    mt={1}
                  >
                    <Grid item xs={6} md={2}>
                      <MDBox mb={2} className="mdsAutocompleteGrid">
                        <Autocomplete
                          value={searchFormData.area}
                          size="small"
                          name="area"
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setSearchFormData({
                                ...searchFormData,
                                ["area"]: newValue,
                              });
                            } else {
                              setSearchFormData({
                                ...searchFormData,
                                ["area"]: "",
                              });
                            }
                          }}
                          options={areaOptions}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Area"
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <MDBox mb={2}>
                        <MDInput
                          name="interview_date"
                          type="date"
                          label="Start Date"
                          fullWidth
                          value={searchFormData.interview_date}
                          onChange={onChangeSearch}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <MDBox mb={2} className="mdsAutocompleteGrid">
                        <Autocomplete
                          value={searchFormData.company_id}
                          size="small"
                          name="company_id"
                          options={companyList}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setSearchFormData({
                                ...searchFormData,
                                ["company_id"]: newValue,
                              });
                            } else {
                              setSearchFormData({
                                ...searchFormData,
                                ["company_id"]: "",
                              });
                            }
                          }}
                          getOptionLabel={(companyList) =>
                            companyList.name || ""
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select Company"
                              InputLabelProps={{ shrink: true }}
                              onChange={(e) =>
                                searchSpot("company", e.target.value)
                              }
                            />
                          )}
                        />
                      </MDBox>
                    </Grid>

                    {/* <Grid item xs={6} md={2}>
                      <MDBox mb={2} className="mdsAutocompleteGrid">
                        <Autocomplete
                          value={searchFormData.position_id}
                          size="small"
                          name="position_id"
                          options={positionList}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setSearchFormData({
                                ...searchFormData,
                                ["position_id"]: newValue,
                              });
                            } else {
                              setSearchFormData({
                                ...searchFormData,
                                ["position_id"]: "",
                              });
                            }
                          }}
                          getOptionLabel={(positionList) =>
                            positionList.setting_value || ""
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select Position"
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                      </MDBox>
                    </Grid> */}

                    <Grid item xs={6} md={2}>
                      <MDBox mb={2} className="mdsAutocompleteGrid">
                        <Autocomplete
                          value={searchFormData.status_id}
                          size="small"
                          name="status_id"
                          options={statusList}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setSearchFormData({
                                ...searchFormData,
                                ["status_id"]: newValue,
                              });
                            } else {
                              setSearchFormData({
                                ...searchFormData,
                                ["status_id"]: "",
                              });
                            }
                          }}
                          getOptionLabel={(statusList) =>
                            statusList.setting_value || ""
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Status"
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <MDBox display="flex" alignItems="center">
                        {/* {downloadCsv && (
                          <>
                            <CSVDownload
                              data={csvData}
                              filename={"my-3534564-file.csv"}
                              headers={csvHeaders}
                              target="_self"
                            />
                          </>
                        )} */}
                        <CSVLink
                          className=" d-none "
                          data={csvData}
                          filename={`Interviews ${dateTimeStamp()}`}
                          headers={csvHeaders}
                          ref={csvLink}
                          target="_self"
                        />
                        <MDButton
                          variant="gradient"
                          color="primary"
                          size="small"
                          type="button"
                          className="searchButton"
                          onClick={exportCsv}
                        >
                          <DownloadIcon /> Export CSV
                        </MDButton>
                      </MDBox>
                    </Grid>
                  </Grid>
                  {/* END : Search and filter block */}

                  {!pageResize && (
                    <Grid item xs={12} md={12} lg={12}>
                      <MDBox
                        borderRadius="lg"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        p={1}
                        sx={{
                          border: ({ borders: { borderWidth, borderColor } }) =>
                            `${borderWidth[1]} solid ${borderColor}`,
                        }}
                      >
                        <Grid item xs={4} md={4} lg={2}>
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="medium"
                          >
                            Area
                          </MDTypography>
                        </Grid>

                        <Grid item xs={4} md={4} lg={2}>
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="medium"
                          >
                            Start Date
                          </MDTypography>
                        </Grid>
                        <Grid item xs={4} md={4} lg={2}>
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="medium"
                          >
                            End Date
                          </MDTypography>
                        </Grid>
                        <Grid item xs={4} md={4} lg={2}>
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="medium"
                          >
                            Company Name
                          </MDTypography>
                        </Grid>
                        <Grid item xs={4} md={4} lg={2}>
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="medium"
                          >
                            Position
                          </MDTypography>
                        </Grid>
                        <Grid item xs={4} md={4} lg={2}>
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="medium"
                          >
                            Status
                          </MDTypography>
                        </Grid>
                        <Grid item xs={4} md={4} lg={1}>
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="medium"
                          >
                            Total Selections
                          </MDTypography>
                        </Grid>
                        <Grid item xs={4} md={4} lg={1}>
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="medium"
                          >
                            Deployment Number
                          </MDTypography>
                        </Grid>
                        <Grid item xs={4} md={4} lg={1}>
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="medium"
                          >
                            Action
                          </MDTypography>
                        </Grid>
                      </MDBox>
                    </Grid>
                  )}
                  {interviewList.map((interviewItem, index) => {
                    let {
                      area,
                      interview_id,
                      application_name,
                      interview_date_time,
                      company_id,
                      position_id,
                      outcome,
                      start_date,
                      end_date,
                      company_info,
                      position_info,
                      status_info,
                      positions_list,
                      total_selections,
                      deployed_number,
                    } = interviewItem;
                    return (
                      <>
                        {!pageResize ? (
                          <Grid item key={interview_id} xs={12} md={12} lg={12}>
                            <MDBox
                              borderRadius="lg"
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                              p={1}
                              sx={{
                                border: ({
                                  borders: { borderWidth, borderColor },
                                }) => `${borderWidth[1]} solid ${borderColor}`,
                              }}
                            >
                              <Grid item xs={4} md={4} lg={2}>
                                <MDTypography
                                  fontSize={pxToRem(14)}
                                  fontWeight="regular"
                                >
                                  {area}
                                </MDTypography>
                              </Grid>
                              <Grid item xs={4} md={4} lg={2}>
                                <MDTypography
                                  fontSize={pxToRem(14)}
                                  fontWeight="regular"
                                >
                                  {dateFormat(start_date)}
                                </MDTypography>
                              </Grid>
                              <Grid item xs={4} md={4} lg={2}>
                                <MDTypography
                                  fontSize={pxToRem(14)}
                                  fontWeight="regular"
                                >
                                  {dateFormat(end_date)}
                                </MDTypography>
                              </Grid>

                              <Grid item xs={4} md={4} lg={2}>
                                <MDTypography
                                  fontSize={pxToRem(14)}
                                  fontWeight="regular"
                                >
                                  {company_info?.name}
                                </MDTypography>
                              </Grid>

                              <Grid item xs={4} md={4} lg={2}>
                                <MDTypography
                                  fontSize={pxToRem(14)}
                                  fontWeight="regular"
                                >
                                  {positions_list?.map((position, i) => {
                                    return (
                                      (i != 0 ? ", " : " ") +
                                      position?.position_info?.setting_value
                                    );
                                  })}
                                </MDTypography>
                              </Grid>
                              <Grid item xs={4} md={4} lg={2}>
                                <MDTypography
                                  fontSize={pxToRem(14)}
                                  fontWeight="regular"
                                >
                                  {status_info?.setting_value}
                                </MDTypography>
                              </Grid>
                              <Grid item xs={4} md={4} lg={1}>
                                <MDTypography
                                  fontSize={pxToRem(14)}
                                  fontWeight="regular"
                                >
                                  {total_selections}
                                </MDTypography>
                              </Grid>
                              <Grid item xs={4} md={4} lg={1}>
                                <MDTypography
                                  fontSize={pxToRem(14)}
                                  fontWeight="regular"
                                >
                                  {deployed_number}
                                </MDTypography>
                              </Grid>

                              <Grid item xs={4} md={4} lg={1}>
                                <IconButton
                                  size="small"
                                  disableRipple
                                  color="inherit"
                                  onClick={() =>
                                    handleOpenDialog(interviewItem)
                                  }
                                >
                                  <RemoveRedEye />
                                </IconButton>
                                {showEditButton && (
                                  <IconButton
                                    size="small"
                                    disableRipple
                                    color="inherit"
                                    onClick={() =>
                                      navigate(`/interview/${interview_id}`)
                                    }
                                  >
                                    <Edit />
                                  </IconButton>
                                )}
                                {showDeleteButton && (
                                  <IconButton
                                    size="small"
                                    disableRipple
                                    color="inherit"
                                    onClick={()=>{
                                      handleOpenDeleteDialog(interview_id)
                                    }}
                                  >
                                    <Delete />
                                  </IconButton>
                                )}
                              </Grid>
                            </MDBox>
                          </Grid>
                        ) : (
                          <MobileList
                            key={interview_id}
                            interviewItem={interviewItem}
                            index={index + 1}
                            handleOpenDialog={handleOpenDialog}
                            showEditButton={showEditButton}
                            showDeleteButton={showDeleteButton}
                            handleOpenDeleteDialog={handleOpenDeleteDialog}
                          />
                        )}
                      </>
                    );
                  })}
                </Grid>
                <Grid item xs={12} md={3}>
                  <Stack spacing={2} mb={2} ml={2}>
                    <Pagination
                      count={pageCount}
                      page={pageIndex}
                      onChange={doPaginationChange}
                      variant="outlined"
                    />
                  </Stack>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <ViewMoreRa
        openDialog={openDialog}
        interviewData={interviewData}
        handleCloseDialog={handleCloseDialog}
      />
      <DeleteInterview
      interview_id={interviewData.interview_id}
      handleCloseDeleteDialog={handleCloseDeleteDialog}
      openDialogForDelete={openDialogForDelete}/>
      <Footer />
    </DashboardLayout>
  );
}

export default InterviewList;
