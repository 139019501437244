// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { fetchData, deleteData } from "utils/clientFunctions";
import useSWR from "swr";
import { useEffect, useRef, useState } from "react";
import { Token } from "utils/clientFunctions";
import MDButton from "components/MDButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  Tooltip,
} from "@mui/material";
import { settingDefault } from "utils/clientFunctions";
import { ErrorMessage, Field, Form, Formik } from "formik";
import MDInput from "components/MDInput";
import { Link, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MDAvatar from "components/MDAvatar";
import { Delete, Edit, RemoveRedEye, TramSharp } from "@mui/icons-material";
import { useAuth } from "utils/auth";
import pxToRem from "assets/theme/functions/pxToRem";
import MobileList from "../components/mobileList";
import { dateFormat } from "utils/clientFunctions";
import { CardTopBoxAdd } from "layouts/commonComponents/cardTopBoxAdd";
import { dateTimeFormat } from "utils/clientFunctions";
import { useSelector, useDispatch } from "react-redux";
import { getPermissions, selectPermissions } from "reducers/permissionSlice";
import { firstLetterCapital } from "utils/clientFunctions";
import { liveSearchData } from "layouts/commonComponents/liveSearchData";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { CSVDownload, CSVLink } from "react-csv";
import DownloadIcon from "@mui/icons-material/Download";
import { dateTimeStamp } from "utils/clientFunctions";
import { useToasts } from "react-toast-notifications";
import { charat } from "stylis";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BookingList() {
  const csvLink = useRef();
  const [pageIndex, setPageIndex] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [pageResize, setPageResize] = useState(
    window.innerWidth < 992 ? true : false
  );
  const navigate = useNavigate();
  const { addToast } = useToasts();
  //Start : For permission
  const dispatch = useDispatch();
  var permissions = useSelector(selectPermissions);
  if (
    !(
      permissions?.user_id === 1 || permissions?.permission?.includes("bokView")
    )
  ) {
    navigate("/dashboard");
  }
  let showAddButton =
    permissions?.user_id === 1 || permissions?.permission?.includes("bokCreate")
      ? true
      : false;
  let showEditButton =
    permissions?.user_id === 1 || permissions?.permission?.includes("bokEdit")
      ? true
      : false;
  let showDeleteButton =
    permissions?.user_id === 1 || permissions?.permission?.includes("bokDelete")
      ? true
      : false;
  useEffect(() => {
    dispatch(getPermissions());
  }, []);
  //End : For permission

  const [bookingList, setBookingList] = useState([]);
  const [bookingTypeList, setBookingTypeList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  //View More RA data
  const [bookingData, setbookingData] = useState({});
  const { logout } = useAuth();

  const initialLoadData = async () => {
    let queryUrl = getQueryParams();
    const url = `booking/list${queryUrl}`;
    const data = await fetchData(url);
    if (
      data?.name === "JsonWebTokenError" ||
      data?.name === "TokenExpiredError" ||
      data?.message === "You are not authorized"
    ) {
      logout();
    }
    if (data && data.data) {
      let bookingCount = Math.ceil(data.count / 10);
      setPageCount(bookingCount);
      setBookingList(data.data || []);
    }
  };

  //Search & Filter initial data
  const [searchFormData, setSearchFormData] = useState({
    booking_type: "",
    booking_date: "",
    reason: "",
    status_id: "",
  });

  useEffect(() => {
    initialLoadData();
  }, [
    pageIndex,
    searchFormData.booking_type,
    searchFormData.booking_date,
    searchFormData.reason,
    searchFormData.status_id,
  ]);

  useEffect(() => {
    //Get booking type status list
    const bookingTypeUrl = `setting/type/14`;
    getFetchData(bookingTypeUrl).then((res) => {
      setBookingTypeList(res.data);
    });

    //Get call report status list
    const statusUrl = `setting/type/8`;
    getFetchData(statusUrl).then((res) => {
      setStatusList(res.data);
    });
  }, []);

  const doPaginationChange = (event, value) => {
    setPageIndex(value);
  };

  //Dialog popup
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogForDelete, setDialogForDelete] = useState(false) //Dialog state for deleting booking
  const [selectedBookingId, setSelectedBookingId] = useState(null); //state to store booking_id
  const handleOpenDialog = (callReportInfo) => {
    setbookingData(callReportInfo);
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setbookingData({});
    setOpenDialog(false);
  };

  const handleOpenDeleteDialog = (booking_id) =>{
    setDialogForDelete(true);
    console.log("Booking ID to delete: for debugging", booking_id); 
    setSelectedBookingId(booking_id);
  }

  const handleCloseDeleteDialog = () =>{
    setDialogForDelete(false);
    setSelectedBookingId(null); //Clear the selected booking id
  }

  window.addEventListener("resize", () => {
    if (window.innerWidth < 992) {
      setPageResize(true);
    } else {
      setPageResize(false);
    }
  });

  const getFetchData = async (settingUrl) => {
    return await fetchData(settingUrl);
  };

  const displayTypoInfo = (label, value) => {
    return (
      <>
        <Grid item xs={6} md={6} lg={6}>
          <MDTypography variant="h6" fontWeight="medium">
            {label}
          </MDTypography>
        </Grid>
        <Grid item xs={6} md={6} lg={6}>
          <MDTypography variant="h6" fontWeight="regular">
            {value}
          </MDTypography>
        </Grid>
      </>
    );
  };

  const getCountryFlag = (countryInfo) => {
    return (
      <>
        <MDTypography variant="h6" fontWeight="regular">
          <MDAvatar
            src={`https://flagcdn.com/w40/${countryInfo?.code.toLowerCase()}.png`}
            name={countryInfo?.name}
            size="md"
            style={{ display: "inline-block", overflow: "visible" }}
            sx={{ width: 20, height: 20 }}
          />{" "}
          {countryInfo?.name}
        </MDTypography>
      </>
    );
  };

const DeleteBooking = ({openDialogForDelete, handleCloseDeleteDialog}) => {
  const handleConfirmDelete = async () => {
    if(!selectedBookingId) {
      console.error("No booking selected for deletion");
      return;
    }

    //Close the dialog immediately
    setDialogForDelete(false);

    //Add logic to handle Booking deletion
    const url = `booking/${selectedBookingId}`;
    try {
      const response =  await deleteData(url);

      if(response?.status) {
        //Success handling
        addToast("Booking deleted successfully", {
          appearance: "success",
          autoDismiss: true,
        });

        //Refresh the booking list
        setBookingList((prevList) => prevList.filter((booking) => booking.booking_id !== selectedBookingId ));

        //Reset the selected booking Id
        setSelectedBookingId(null);
      } else {
        //Handle errors
        if(response?.message) {
          addToast(response.message, {appearance: "error", autoDismiss: true});
        }
        if(response?.errors) {
          response.errors.forEach((error) => {
            let errorMessage = error.message
            .replace(/['"]+/g, "")
            .replace(/_/g, " ");
            errorMessage = errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
            addToast(errorMessage, {appearance: "error", autoDismiss: true});
          });
        }
      }
    } catch (error) {
      console.error("Error deleting Booking:", error);
      addToast("An error occured while deleting the booking", {
        appearance: "error",
        autoDismiss: true,
      });
    }

  }
  return (<>
  <BootstrapDialog
    onClose={handleCloseDeleteDialog}
    aria-labelledby="customized-dialog-title"
    open={openDialogForDelete}
    fullScreen={false}
  >
    <DialogTitle sx={{m: 0}} id="customized-dialog-title">
      {"Delete Booking"}
    </DialogTitle>
    <IconButton 
    aria-label= "close"
    onClick={handleCloseDeleteDialog}
    sx={{
      position: "absolute",
      right: 8,
      top: 8,
      color: (theme) => theme.palette.grey[500],
    }}
    >
      <CloseIcon />
    </IconButton>
    <DialogContent>
      <MDTypography variant="body1" gutterBottom>
        Are you sure you want to delete this booking?
      </MDTypography>
    </DialogContent>
    <DialogActions>
      <MDButton
        onClick={handleConfirmDelete}
        variant="gradient"
        color="error"
        size="small"
      >
        Delete
      </MDButton>
      <MDButton
          onClick={handleCloseDeleteDialog}
          variant="gradient"
          color="info"
          size="small"
        >
          Cancel
        </MDButton>
    </DialogActions>

    </BootstrapDialog>
  </>)
}


  const ViewMoreRa = ({ openDialog, bookingData, handleCloseDialog }) => {
    let {
      booking_id,
      booking_date,
      reason,
      person_name,
      contact_number,
      booking_place_name,
      currency_info,
      amount,
      booking_type_info,
      contact_code_info,
      status_info,
      remarks,
      document_list,
    } = bookingData;

    return (
      <BootstrapDialog
        onClose={handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
        fullScreen={window.innerWidth < 768 ? true : false}
      >
        <DialogTitle sx={{ m: 0 }} id="customized-dialog-title">
          {booking_type_info?.setting_value}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid container p={2} spacing={1}>
            {/* {displayTypoInfo("Booking Type", booking_type_info?.setting_value)} */}
            {displayTypoInfo("Booking Date", dateFormat(booking_date))}
            {displayTypoInfo("Reason", reason)}
            {displayTypoInfo("Booking Place", booking_place_name)}
            {displayTypoInfo("Person Name", person_name)}
            {displayTypoInfo(
              "Contact Number",
              contact_code_info?.dial_code + " " + contact_number
            )}
            {displayTypoInfo(
              "Amount",
              currency_info?.currency_code + " " + amount * 1
            )}
            {displayTypoInfo("Status", status_info?.setting_value)}
            {displayTypoInfo("Remarks", remarks)}

            <Grid item xs={12} md={12} lg={12}>
              <MDTypography variant="h6" fontWeight="medium">
                {document_list?.length ? (
                  <MDBox
                    py={1}
                    px={1}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      List of Documents
                    </MDTypography>
                  </MDBox>
                ) : (
                  ""
                )}
              </MDTypography>
            </Grid>
            {document_list?.map((document, i) => {
              const {
                document_name,
                issue_date,
                expiry_date,
                upload_document,
                issue_country_info,
              } = document;
              return (
                <>
                  <Grid item xs={12} md={12} lg={12}>
                    <MDTypography variant="h6" fontWeight="medium">
                      {i + 1}.
                    </MDTypography>
                  </Grid>
                  {displayTypoInfo("Name", document_name)}
                  {displayTypoInfo(
                    "Issue Country",
                    getCountryFlag(issue_country_info)
                  )}
                  {displayTypoInfo(
                    "Issue Date",
                    issue_date ? dateFormat(issue_date) : "-"
                  )}
                  {displayTypoInfo(
                    "Expiry Date",
                    expiry_date ? dateFormat(expiry_date) : "-"
                  )}
                  {displayTypoInfo(
                    "Document",
                    <Link
                      to={`${process.env.REACT_APP_URI_PATH}${upload_document}`}
                      target="_blank"
                    >
                      View Document
                    </Link>
                  )}
                </>
              );
            })}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseDialog}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
    );
  };

  //START : Search and Filter form data
  //Handle onchange search and filter
  const onChangeSearch = (event) => {
    let name = event.target.name;
    let val = event.target.value;
    setSearchFormData({ ...searchFormData, [name]: val });
  };
  const [csvData, setCsvData] = useState([]);
  const [downloadCsv, setDownloadCsv] = useState(false);

  let csvHeaders = [
    { label: "Booking Type", key: "booking_type" },
    { label: "Date of Booking", key: "date_of_booking" },
    { label: "Reason", key: "reason" },
    { label: "Booking Place", key: "booking_place_name" },
    { label: "Booking Amount", key: "booking_amount" },
    { label: "Person Name", key: "person_name" },
    { label: "Contact Number", key: "contact_number" },
    { label: "Status", key: "status" },
  ];
  //Prepare the csv data for export
  const exportCsv = async () => {
    let queryUrl = getQueryParams();
    queryUrl = queryUrl + `&download=1`;
    getFetchData(`booking/list${queryUrl}`).then(async (res) => {
      if (res?.data) {
        let exportData = [];
        await Promise.all(
          res?.data.map((report) => {
            report.booking_type = report?.booking_type_info?.setting_value;
            report.date_of_booking = report?.booking_date
              ? dateFormat(report?.booking_date)
              : "-";
            report.booking_amount =
              report?.currency_info?.currency_code + " " + report?.amount;

            report.contact_number =
              report?.contact_code_info?.dial_code +
              " " +
              report?.contact_number;

            report.status = report?.status_info?.setting_value;
            exportData.push(report);
          })
        );
        setCsvData(exportData); //Set the csv data
        setDownloadCsv(true); // Show the CSVDwonload link
        setTimeout(() => {
          csvLink?.current?.link?.click();
          setDownloadCsv(false); // Hide the CSVDwonload link
        }, 100);
      }
    });
  };
  //Prepare the query string for search and filter
  const getQueryParams = () => {
    let queryUrl = `?page=${pageIndex}`;

    queryUrl = searchFormData?.booking_type?.setting_id
      ? queryUrl + "&booking_type=" + searchFormData?.booking_type?.setting_id
      : queryUrl;

    queryUrl = searchFormData?.booking_date
      ? queryUrl + "&booking_date=" + searchFormData?.booking_date
      : queryUrl;

    queryUrl = searchFormData.reason
      ? queryUrl + "&reason=" + searchFormData.reason
      : queryUrl;

    queryUrl = searchFormData.status_id?.setting_id
      ? queryUrl + "&status_id=" + searchFormData.status_id?.setting_id
      : queryUrl;

    return queryUrl;
  };
  //END : Search and Filter form data

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <CardTopBoxAdd
                title={"Bookings"}
                link={"/booking"}
                showAddButton={showAddButton}
              />
              <MDBox>
                <Grid container p={2} spacing={2}>
                  {/* START : Search and filter block */}
                  <Grid
                    container
                    className="searchFilterBlock"
                    p={2}
                    spacing={2}
                    mt={1}
                  >
                    <Grid item xs={6} md={2}>
                      <MDBox mb={2} className="mdsAutocompleteGrid">
                        <Autocomplete
                          value={searchFormData.booking_type}
                          size="small"
                          name="booking_type"
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setSearchFormData({
                                ...searchFormData,
                                ["booking_type"]: newValue,
                              });
                            } else {
                              setSearchFormData({
                                ...searchFormData,
                                ["booking_type"]: "",
                              });
                            }
                          }}
                          options={bookingTypeList}
                          getOptionLabel={(bookingTypeList) =>
                            bookingTypeList.setting_value || ""
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Booking Type"
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                      </MDBox>
                    </Grid>

                    <Grid item xs={6} md={2}>
                      <MDBox mb={2}>
                        <MDInput
                          type="date"
                          name="booking_date"
                          label="Booking Date"
                          fullWidth
                          value={searchFormData.booking_date}
                          onChange={onChangeSearch}
                        />
                      </MDBox>
                    </Grid>

                    <Grid item xs={6} md={2}>
                      <MDBox mb={2}>
                        <MDInput
                          type="text"
                          name="reason"
                          label="Booking Reason"
                          fullWidth
                          value={searchFormData.reason}
                          onChange={onChangeSearch}
                        />
                      </MDBox>
                    </Grid>

                    <Grid item xs={6} md={2}>
                      <MDBox mb={2} className="mdsAutocompleteGrid">
                        <Autocomplete
                          value={searchFormData.status_id}
                          size="small"
                          name="status_id"
                          options={statusList}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setSearchFormData({
                                ...searchFormData,
                                ["status_id"]: newValue,
                              });
                            } else {
                              setSearchFormData({
                                ...searchFormData,
                                ["status_id"]: "",
                              });
                            }
                          }}
                          getOptionLabel={(statusList) =>
                            statusList.setting_value || ""
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Status"
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <MDBox display="flex" alignItems="center">
                        {/* {downloadCsv && (
                          <>
                            <CSVDownload
                              data={csvData}
                              filename={"my-3534564-file.csv"}
                              headers={csvHeaders}
                              target="_self"
                            />
                          </>
                        )} */}
                        <CSVLink
                          className=" d-none "
                          data={csvData}
                          filename={`Bookings ${dateTimeStamp()}`}
                          headers={csvHeaders}
                          ref={csvLink}
                          target="_self"
                        />
                        <MDButton
                          variant="gradient"
                          color="primary"
                          size="small"
                          type="button"
                          className="searchButton"
                          onClick={exportCsv}
                        >
                          <DownloadIcon /> Export CSV
                        </MDButton>
                      </MDBox>
                    </Grid>
                  </Grid>
                  {/* END : Search and filter block */}

                  {!pageResize && (
                    <Grid item xs={12} md={12} lg={12}>
                      <MDBox
                        borderRadius="lg"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        p={1}
                        sx={{
                          border: ({ borders: { borderWidth, borderColor } }) =>
                            `${borderWidth[1]} solid ${borderColor}`,
                        }}
                      >
                        <Grid item xs={4} md={4} lg={2}>
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="medium"
                          >
                            Booking Type
                          </MDTypography>
                        </Grid>
                        <Grid item xs={4} md={4} lg={2}>
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="medium"
                          >
                            Date of Booking
                          </MDTypography>
                        </Grid>
                        <Grid item xs={4} md={4} lg={2}>
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="medium"
                          >
                            Booking Reason
                          </MDTypography>
                        </Grid>
                        <Grid item xs={4} md={4} lg={2}>
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="medium"
                          >
                            Booking Amount
                          </MDTypography>
                        </Grid>
                        <Grid item xs={4} md={4} lg={2}>
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="medium"
                          >
                            Status
                          </MDTypography>
                        </Grid>
                        <Grid item xs={4} md={4} lg={1}>
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="medium"
                          >
                            Action
                          </MDTypography>
                        </Grid>
                      </MDBox>
                    </Grid>
                  )}
                  {bookingList.map((bookingItem, index) => {
                    let {
                      booking_id,
                      booking_date,
                      reason,
                      currency_info,
                      amount,
                      booking_type_info,
                      status_info,
                    } = bookingItem;
                    return (
                      <>
                        {!pageResize ? (
                          <Grid item key={booking_id} xs={12} md={12} lg={12}>
                            <MDBox
                              borderRadius="lg"
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                              p={1}
                              sx={{
                                border: ({
                                  borders: { borderWidth, borderColor },
                                }) => `${borderWidth[1]} solid ${borderColor}`,
                              }}
                            >
                              <Grid item xs={4} md={4} lg={2}>
                                <MDTypography
                                  fontSize={pxToRem(14)}
                                  fontWeight="regular"
                                >
                                  {booking_type_info?.setting_value}
                                </MDTypography>
                              </Grid>
                              <Grid item xs={4} md={4} lg={2}>
                                <MDTypography
                                  fontSize={pxToRem(14)}
                                  fontWeight="regular"
                                >
                                  {dateFormat(booking_date)}
                                </MDTypography>
                              </Grid>

                              <Grid item xs={4} md={4} lg={2}>
                                <MDTypography
                                  fontSize={pxToRem(14)}
                                  fontWeight="regular"
                                >
                                  {reason}
                                </MDTypography>
                              </Grid>
                              <Grid item xs={4} md={4} lg={2}>
                                <MDTypography
                                  fontSize={pxToRem(14)}
                                  fontWeight="regular"
                                >
                                  {currency_info?.currency_code +
                                    " " +
                                    amount * 1}
                                </MDTypography>
                              </Grid>
                              <Grid item xs={4} md={4} lg={2}>
                                <MDTypography
                                  fontSize={pxToRem(14)}
                                  fontWeight="regular"
                                >
                                  {status_info?.setting_value}
                                </MDTypography>
                              </Grid>

                              <Grid item xs={4} md={4} lg={1}>
                                <IconButton
                                  size="small"
                                  disableRipple
                                  color="inherit"
                                  onClick={() => handleOpenDialog(bookingItem)}
                                >
                                  <RemoveRedEye />
                                </IconButton>
                                {showEditButton && (
                                  <IconButton
                                    size="small"
                                    disableRipple
                                    color="inherit"
                                    onClick={() =>
                                      navigate(`/booking/${booking_id}`)
                                    }
                                  >
                                    <Edit />
                                  </IconButton>
                                )}
                                {showDeleteButton && (
                                  <IconButton
                                    size="small"
                                    disableRipple
                                    color="inherit"
                                    onClick={()=>{
                                      handleOpenDeleteDialog(booking_id)
                                    }}
                                  >
                                    <Delete />
                                  </IconButton>
                                )}
                              </Grid>
                            </MDBox>
                          </Grid>
                        ) : (
                          <MobileList
                            key={booking_id}
                            bookingItem={bookingItem}
                            index={index + 1}
                            handleOpenDialog={handleOpenDialog}
                            showEditButton={showEditButton}
                            showDeleteButton={showDeleteButton}
                            handleOpenDeleteDialog={handleOpenDeleteDialog}
                          />
                        )}
                      </>
                    );
                  })}
                </Grid>
                <Grid item xs={12} md={3}>
                  <Stack spacing={2} mb={2} ml={2}>
                    <Pagination
                      count={pageCount}
                      page={pageIndex}
                      onChange={doPaginationChange}
                      variant="outlined"
                    />
                  </Stack>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <ViewMoreRa
        openDialog={openDialog}
        bookingData={bookingData}
        handleCloseDialog={handleCloseDialog}
      />
      <DeleteBooking
      booking_id={bookingData.booking_id}
      handleCloseDeleteDialog={handleCloseDeleteDialog}
      openDialogForDelete={openDialogForDelete}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default BookingList;
